export const SET_ERROR = 'set-error';
export const CLEAR_ERROR = 'clear-error';

export const setErrorAction = error => ({
  type: SET_ERROR,
  payload: { error }
});

export const clearErrorAction = () => ({
  type: CLEAR_ERROR
});
