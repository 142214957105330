import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TERMS_RECAPTCHA_KEY } from '../constants';

const renderTermsRecaptcha = field => (
  <div className="row col-12 justify-content-center">
    <ReCAPTCHA
      sitekey={TERMS_RECAPTCHA_KEY}
      onChange={field.input.onChange}
    />
    <div className="row col-12 justify-content-center">
      {
        field.meta.touched && field.meta.error && <span className="errorMsg">{field.meta.error}</span>
      }
    </div>
  </div>
);

export default renderTermsRecaptcha;
