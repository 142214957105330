import React, { Fragment } from 'react';
import { Field } from 'redux-form/immutable';
import validations from '../src/components/ReduxForm/validations';
import InFormLogo from '../src/assets/images/logos/in_form_logo.png';
import Input from '../src/components/ReduxForm/fields/Input/Input';
import renderRegisterRecaptcha from './renderRegisterRecaptcha';

const InputHalfLength = props => (
  <div className="col-md-5 col-sm-10">
    <Input {...props} />
  </div>
);

const InputFullLength = props => (
  <div className="col-10col-md-10 col-sm-10">
    <Input {...props} />
  </div>
);

const maxLengthValidator = validations.maxLength(40);
const passwordMinLengthValidator = validations.minLength(8);
const passwordConfirmValidator = validations.fieldMustMatch('password');

const RegisterFormFields = () => (
  <Fragment>
    <div className="row justify-content-center registerWrapper">
      <div className="flex col">
        <div className="row justify-content-center inFormLogo">
          <img src={InFormLogo} alt="logo" />
        </div>
        <div className="row justify-content-center">
          <InputHalfLength
            customClass="inputGeneral"
            placeholder="Enter your first name"
            label="FIRST NAME"
            name="firstName"
            validate={[validations.required, validations.onlyLetters, maxLengthValidator]}
          />
          <InputHalfLength
            customClass="inputGeneral"
            placeholder="Enter your last name"
            label="LAST NAME"
            name="lastName"
            validate={[validations.required, validations.onlyLetters, maxLengthValidator]}
          />
        </div>
        <div className="row justify-content-center">
          <InputFullLength
            customClass="inputGeneral"
            placeholder="Enter your e-mail"
            label="E-MAIL"
            name="email"
            validate={[validations.required, validations.email, maxLengthValidator]}
          />
        </div>
        <div className="row justify-content-center">
          <InputFullLength
            customClass="inputGeneral"
            placeholder="Enter company"
            label="COMPANY"
            name="company"
            validate={[maxLengthValidator]}
          />
        </div>
        <div className="row justify-content-center">
          <InputFullLength
            type="password"
            customClass="inputGeneral"
            placeholder="Enter password"
            label="PASSWORD"
            name="password"
            validate={[validations.required, passwordMinLengthValidator, maxLengthValidator]}
          />
        </div>
        <div className="row justify-content-center">
          <InputFullLength
            type="password"
            customClass="inputGeneral"
            placeholder="Confirm Password"
            label="CONFIRM PASSWORD"
            name="confirmPassword"
            validate={[validations.required, passwordMinLengthValidator, maxLengthValidator, passwordConfirmValidator]}
          />
        </div>
        <div className="row justify-content-center">
          <Input
            type="text"
            hidden
            name="facebookUserId"
          />
        </div>
      </div>
    </div>
    { process.env.DISABLE_CAPTCHA ? <Fragment /> :
    <Field
      name="recaptcha"
      component={renderRegisterRecaptcha}
      validate={validations.required}
    />
    }
  </Fragment>
);

export default RegisterFormFields;
