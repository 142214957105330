import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialize } from 'redux-form/lib/immutable';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form/immutable';
import { parse } from 'query-string';
import { Checkbox } from '../src/components';
import { TopLogo } from '../src/components/images';
import RegisterSocial from '../src/components/Social';
import RegisterFormFields from './RegisterFormFields';
import './register.scss';
import RegisterModal from './RegisterModal';
import registerFormSubmissionHandler from './registerFormSubmissionHandler';
import validations from '../src/components/ReduxForm/validations';
import { getApiRequest } from '../core/utils/requestUtils';
import { registerRedirect as googleLoginRedirect, REGISTER_GOOGLE_REDIRECT } from '../Login/googleConstants';
import { registerRedirect as facebookLoginRedirect, REGISTER_FACEBOOK_REDIRECT } from '../Login/facebookConstants';

const socialParams = {
  '/register-google': {
    redirect: googleLoginRedirect,
    provider: 'google'
  },
  '/register-facebook': {
    redirect: facebookLoginRedirect,
    provider: 'facebook'
  }
};

const getUserInfoRequest = (code, provider, redirect) => getApiRequest({
  target: `/api/social/userinfo?code=${code}&provider=${provider}&redirect=${redirect}`,
  successHandler: (dispatch, response) => {
    dispatch(initialize('register-form', response));
  }
});

const mapStateToProps = state => ({
  userInfo: state.getIn(['register', 'userInfo'])
});

const mapDispatchToProps = dispatch => ({
  getUserInfo: ({ code, provider, redirect }) => {
    if (code && provider) {
      getUserInfoRequest(code, provider, redirect)(null, dispatch);
    }
  }
});

class Register extends React.Component {
  componentWillMount() {
    const { code } = parse(this.props.location.search);
    const { pathname } = this.props.location;
    if (Object.keys(socialParams).includes(pathname)) {
      this.props.getUserInfo({ code, ...socialParams[pathname] });
    }
  }
  render() {
    return (
      <div className="contentWrapper darkPageBg">
        <div className="container">
          <div className="row justify-content-center">
            <form onSubmit={this.props.handleSubmit(registerFormSubmissionHandler)} className="formWrapper col-md-5 col-sm-8 col">
              <div className="row formHeader">
                <div className="col-4">
                  <p className="signUpTitle">SIGN UP</p>
                </div>
                <div className="col-8 text-right topLogoRegister">
                  <img src={TopLogo} alt="logo" />
                </div>
              </div>
              <RegisterFormFields />
              <div className="row justify-content-center tos">
                <div className="form-check">
                  <div className="form-check-input">
                    <Checkbox name="terms" validate={validations.required} />
                  </div>
                  <label htmlFor="accept-terms" className="tncLabel">Accept <a href="">Terms</a> &amp; <a href="">Conditions</a></label>
                </div>
              </div>
              <div className="row justify-content-center text-center">
                <div className="col-10">
                  <button type="submit" className="action-btn">SIGN UP</button>
                </div>
              </div>
              <RegisterSocial googleRedirect={REGISTER_GOOGLE_REDIRECT} facebookRedirect={REGISTER_FACEBOOK_REDIRECT} />
            </form>
            <RegisterModal />
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  location: PropTypes.shape(),
  getUserInfo: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default reduxForm({
  form: 'register-form',
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Register)));
