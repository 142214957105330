import { Map } from 'immutable';
import { CLOSE_MODAL, SET_MODAL_STATE } from './actions';
import { storeLiterals } from './constants';

export const modalReducer = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SET_MODAL_STATE:
      return state
        .set(storeLiterals.OPEN_MODAL, action.payload.openModalName)
        .set(storeLiterals.MODAL_STATUS, action.payload.modalStatus);
    case CLOSE_MODAL:
      return Map();
    default:
      return state;
  }
};
