import productionServicesConstructor from './ProductionServices';

export const PRODUCTION_CREW = {
  api: 'PRODUCTION_CREW',
  title: 'PRODUCTION CREW',
  component: productionServicesConstructor('productionCrew')
};

export const EQUIPMENT = {
  api: 'EQUIPMENT',
  title: 'EQUIPMENT',
  component: productionServicesConstructor('equipment')
};

export const ART_DEPARTMENT = {
  api: 'ART_DEPARTMENT',
  title: 'ART DEPARTMENT',
  component: productionServicesConstructor('artDepartment')
};

export const SUBSISTENCE = {
  api: 'TRAVEL_SUBSISTENCE_HOTEL_AND_OFFICE',
  title: 'TRAVEL, SUBSISTENCE, HOTEL AND OFFICE',
  component: productionServicesConstructor('subsistence')
};


const categories = [
  PRODUCTION_CREW,
  EQUIPMENT,
  ART_DEPARTMENT,
  SUBSISTENCE
];

export const getTitleByServiceCategory =
    serviceCategory => categories.filter(category => category.api === serviceCategory)[0].title;
