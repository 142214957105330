import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = state => ({
  projectTitle: formValueSelector('generalInformation')(state, 'title'),
  startDate: formValueSelector('generalInformation')(state, 'startDate'),
  endDate: formValueSelector('generalInformation')(state, 'endDate'),
  description: formValueSelector('generalInformation')(state, 'description'),
  projectCountry: formValueSelector('generalInformation')(state, 'country'),
  existingQuote: state.getIn(['wizard', 'quote'])
});

export const ProductionServiceTitle = connect(mapStateToProps)(({
  projectTitle, startDate, endDate, showDescription, description, projectCountry, existingQuote
}) => (
  <React.Fragment>
    <div className="stepHead text-center col">
      <h1>{projectTitle}</h1>
      <h2>{projectCountry}</h2>
      <div className="row stepHeadMeta">
        <div className="col col-md-6 col-6 justify-content-center">
          <h3>FROM: {new Date(startDate).toLocaleDateString()}</h3>
        </div>
        <div className="col col-md-6 col-6 justify-content-center">
          <h3>TO: {new Date(endDate).toLocaleDateString()}</h3>
        </div>
      </div>
      {
        !showDescription && existingQuote &&
        <h3>
          <br />
          <Link className="col col-md-6 col-6 justify-content-center" to="/budget">View Saved Quote</Link>
        </h3>
      }
    </div>
    {
      showDescription &&
      <div className="col budgetProjectDesc">
        <h2>PROJECT DESCRIPTION</h2>
        <p className="text-center">
          {description}
        </p>
      </div>
    }
  </React.Fragment>
));

ProductionServiceTitle.propTypes = {
  showDescription: PropTypes.bool,
  projectTitle: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  projectCountry: PropTypes.string
};
