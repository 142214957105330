import authTokenHandler from '../auth/authTokenHandler';
import { isFunction } from './langUtils';

export const JSON_FORMAT = 'application/json';

const createHeaders = authorized => (
  authorized
    ? {
      Accept: JSON_FORMAT,
      'Content-Type': JSON_FORMAT,
      Authorization: authTokenHandler.getAuthToken()
    }
    : {
      Accept: JSON_FORMAT,
      'Content-Type': JSON_FORMAT,
    }
);

function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

const postRequestParamLoader = (values, authorized) => ({
  headers: createHeaders(authorized),
  method: 'post',
  body: JSON.stringify({ ...values })
});

const getRequestParamLoader = authorized => ({
  headers: createHeaders(authorized),
  method: 'get'
});

export const postApiRequest = ({
  target, beforeRequestHandler, httpResponseHandler, successHandler, errorHandler, exception, authorized, transformValues, conflictHandler
}) => (values, dispatch) => {
  if (isFunction(beforeRequestHandler)) {
    beforeRequestHandler(dispatch, values);
  }

  return fetch(target, postRequestParamLoader(transformValues ? transformValues(values) : (values && values.toJS()), authorized)).then((httpResponse) => {
    if (httpResponse.status === 409) {
      conflictHandler(dispatch);
    }
    if (httpResponse.ok) {
      if (isFunction(httpResponseHandler)) {
        httpResponseHandler(dispatch, httpResponse);
      }
      return sleep(1000).then(() => httpResponse.json());
    }
    if (isFunction(errorHandler)) {
      errorHandler(dispatch);
    }
    if (exception instanceof Error) {
      throw exception;
    } else {
      throw new Error('Something went very very wrong!');
    }
  }).then((responseJson) => {
    if (isFunction(successHandler)) {
      successHandler(dispatch, responseJson);
    }
    return responseJson;
  });
};

export const getApiRequest = ({
  target, beforeRequestHandler, httpResponseHandler, successHandler, errorHandler, exception, authorized
}) => (values, dispatch) => {
  if (isFunction(errorHandler)) {
    beforeRequestHandler(dispatch);
  }

  return fetch(target, getRequestParamLoader(authorized)).then((httpResponse) => {
    if (httpResponse.ok) {
      if (isFunction(httpResponseHandler)) {
        httpResponseHandler(dispatch, httpResponse);
      }
      return sleep(1000).then(() => httpResponse.json());
    }
    if (isFunction(errorHandler)) {
      errorHandler(dispatch);
    }
    if (exception instanceof Error) {
      throw exception;
    } else {
      throw new Error('Something went very very wrong!');
    }
  }).then((responseJson) => {
    if (isFunction(successHandler)) {
      successHandler(dispatch, responseJson);
    }
    return responseJson;
  });
};

export const postFireForgetUnauthRequest = (url, payload) =>
  fetch(url, postRequestParamLoader(payload))
    .then(resp => resp.status === 201)
    .catch(err => console.error(`Could not post to ${url} : `, err));
