import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { reduxForm, Field } from 'redux-form/immutable';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './termsandconditions.scss';
import validations from '../src/components/ReduxForm/validations';
import renderTermsRecaptcha from '../Wizard/fields/renderTermsRecaptcha';
import Checkbox from '../src/components/ReduxForm/fields/Checkbox/Checkbox';
import RequestBudgetModal from './RequestBudgetModal';

const TermsAndCondition = ({
  handleSubmit, nextStepHandler, currentWizardStep, serviceCategory, projectId, history
}) => (
  <div className="container termsandconditions">
    <div className="row col-12 justify-content-center text-center">
      <h1>TERMS AND CONDITIONS</h1>
    </div>
    <div className="row col-12">
      <p>The sole purpose of this Website is to provide you with an estimated cost of the services, crew or equipment
        you have chosen. This estimated cost is calculated automatically by our average rates data base and does not
        reflect in any way the actual cost of a production, which may vary significantly, once we have received a full
        brief for your request.
      </p>
      <p>The estimated cost you will be provided is only indicative and not bounding to NEEDaFIXER LIMITED (or any of
        its affiliate companies) in any way. The form you are submitting in this Website does not constitute a proposal
        to enter into a formal contract with NEEDaFIXER and we will not construe it as such.
      </p>
      <p>Similarly, the estimated cost you will be provided is not in any way an acceptance of a proposal by our side to
        enter into a formal contract with you and you shall not consider for any reason that we are bound to provide any
        services to you and especially at the estimated cost you will be provided.
      </p>
      <p>Using this Website does not create an agreement or other type of legal relationship between you and NEEDaFIXER
        LIMITED (or any of its affiliate companies). If you wish to cooperate with us for any project, you are kindly
        invited to communicate with us in any of the available means. If we reach an agreement for any of your projects,
        this agreement shall be solely governed by a contract we will mutually agree and execute and not by any elements
        of this Website.
      </p>
    </div>
    <div className="row col-12 justify-content-center">
      <Checkbox name="terms" validate={validations.required} label="Accept Terms and Conditions" />
    </div>
    {/* <div className="row col-12 justify-content-center"> */}
    {/* <span className="errorMsg">Error Message</span> */}
    {/* </div> */}
    <div className="row col-12 justify-content-center tosCaptchaWrapper">
      { process.env.DISABLE_CAPTCHA ? <Fragment /> :
      <Field
        name="recaptcha"
        component={renderTermsRecaptcha}
        validate={validations.required}
      />
      }
    </div>
    <div className="row actionsStripe">
      <button
        onClick={handleSubmit(nextStepHandler(currentWizardStep, serviceCategory, projectId, history))}
        className="nextBtn"
      >
        Calculate My Budget<FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
    <RequestBudgetModal />
  </div>
);

TermsAndCondition.propTypes = {
  handleSubmit: PropTypes.func,
  nextStepHandler: PropTypes.func,
  currentWizardStep: PropTypes.number,
  serviceCategory: PropTypes.string,
  projectId: PropTypes.number,
  history: PropTypes.shape()
};


export default reduxForm({
  form: 'terms'
})(withRouter(TermsAndCondition));
