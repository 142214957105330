import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formValueSelector, reduxForm } from 'redux-form/immutable';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragIcon from '../../src/assets/images/drag.svg';
import { withStepHandler } from '../../src/components/ProgressWidget/stepHanlders';
import Input from '../../src/components/ReduxForm/fields/Input/Input';
import { rowStyle } from '../utils';
import { dateDiffInDays } from '../../core/utils/dateUtils';
import ProductionServiceField from './ProductionServiceField';

const mapStateToProps = (state, { serviceCategory }) => ({
  productionServices: state.getIn(['wizard', 'productionServices', serviceCategory]) || [],
  projectId: formValueSelector('generalInformation')(state, 'id'),
  differenceInDays: dateDiffInDays(
    new Date(formValueSelector('generalInformation')(state, 'startDate')),
    new Date(formValueSelector('generalInformation')(state, 'endDate'))
  )
});

// FIXME: move left arrow to the left

class ProductionService extends React.Component {
  componentDidMount() {
    document.getElementById('steps-container-top').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  render() {
    const {
      titleForType, titleForAmount, numberOfCols, differenceInDays, productionServices, currentWizardStep, serviceCategory, projectId, handleSubmit, history, nextStepHandler, nextStepText, setStepHandler
    } = this.props;
    return (
      <div id="production-services" className="greyTable greyForm stickyTableWrapper row">
        <div className="col stickyTableCol">
          <div className="col stepTableHead d-none d-md-block">
            <div className="row stepTableBorder">
              <div className="col col-5">
                <h4>{titleForType}</h4>
              </div>
              <div className={`col col-md-${5 - numberOfCols} col-12 text-center`}>
                <h4>{titleForAmount}</h4>
              </div>
              {
                numberOfCols === 3 &&
                <div className="col col-2 text-center">
                  <h4>PRE-PRODUCTION DAYS</h4>
                </div>
              }
              <div className={`col col-${5 - numberOfCols} text-center`}>
                <h4>PRODUCTION DAYS</h4>
              </div>
              <div className="col col-1" />
            </div>
          </div>
          <div className="col stickyTableContent">
            {
              productionServices.map(productionService => (
                <div
                  key={`production-service-${productionService}`}
                  style={rowStyle}
                  className="row col-12 draggableStepRow"
                >
                  <ProductionServiceField
                    titleForAmount={titleForAmount}
                    maxQuantity={differenceInDays}
                    numberOfCols={numberOfCols}
                    serviceType={productionService}
                  />
                  <div className="col col-1 d-none d-md-inline">
                    <img alt="draggable" src={DragIcon} />
                  </div>
                </div>
              ))
            }
            <Input
              name="id"
              hidden
            />
          </div>
          <div className="row col-12 actionsStripe">
            <div className="col-6">
              <button onClick={() => setStepHandler(currentWizardStep - 1)} className="nextBtn">
                <FontAwesomeIcon icon={faAngleLeft} />PREVIOUS STEP
              </button>
            </div>
            <div className="col-6">
              <button
                onClick={handleSubmit(nextStepHandler(currentWizardStep, serviceCategory, projectId, history))}
                className="nextBtn"
              >
                {nextStepText}<FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProductionService.propTypes = {
  numberOfCols: PropTypes.number,
  handleSubmit: PropTypes.func,
  currentWizardStep: PropTypes.number,
  productionServices: PropTypes.arrayOf(PropTypes.string),
  serviceCategory: PropTypes.string,
  projectId: PropTypes.number,
  titleForAmount: PropTypes.string,
  titleForType: PropTypes.string,
  differenceInDays: PropTypes.number,
  history: PropTypes.shape(),
  nextStepHandler: PropTypes.func,
  nextStepText: PropTypes.string,
  setStepHandler: PropTypes.func
};

const validate = (values) => {
  const allValues = values.toJS();
  const errors = {};
  Object.entries(allValues).forEach((entry) => {
    const productionService = entry[0];
    const { numberOfPersons, productionDays } = entry[1];
    const numberOfPersonsBoolean = !!numberOfPersons;
    const productionDaysBoolean = !!productionDays;
    if (numberOfPersonsBoolean ^ productionDaysBoolean) {
      errors[productionService] = {};
      if (numberOfPersonsBoolean) {
        errors[productionService].productionDays = 'Cannot be zero!';
      } else {
        errors[productionService].numberOfPersons = 'Cannot be zero!';
      }
    }
  });
  return errors;
};

const productionServicesConstructor = formName => reduxForm({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  validate
})(withStepHandler(connect(mapStateToProps, null)(withRouter(ProductionService))));

export default productionServicesConstructor;
