import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form/immutable';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, DatePicker, Checkbox } from '../../src/components';
import { withStepHandler } from '../../src/components/ProgressWidget/stepHanlders';
import CountriesDropDown from '../fields/CountriesDropDown';
import ProductionDropDown from '../fields/ProductionDropDown';
import validations from '../../src/components/ReduxForm/validations';

const validateDates = (value, values) => {
  const startDate = values && moment(values.get('startDate'));
  const endDate = moment(value);
  if (startDate.isBefore(moment())) {
    return 'Project starting date must be in the future.';
  }
  if (startDate.isAfter(endDate)) {
    return 'End date must be later than start date.';
  }
  return undefined;
};

const maxLengthValidator = validations.maxLength(50);
const textAreaMaxLengthValidator = validations.maxLength(650);

const GeneralInformation = ({ handleSubmit, nextStepHandler }) => (
  <React.Fragment>
    <div className="greyForm row generalInformation">
      <div className="column col-md-8">
        <div className="row inputStripe">
          <div className="col col-md-6 col-12">
            <Input
              name="id"
              hidden
            />
            <Input
              placeholder="Type your title"
              name="title"
              validate={[
                    maxLengthValidator,
                    validations.requiredNonEmpty
                  ]}
            />
          </div>
          <div className="col col-1 d-none d-md-flex align-self-center">
            <strong>IN</strong>
          </div>
          <div className="col col-md-5 col-12">
            <Field
              validate={[
                    validations.required
                  ]}
              name="country"
              component={CountriesDropDown}
            />
          </div>
        </div>
        <div className="row inputStripe">
          <Input
            validate={[
                  validations.requiredNonEmpty,
                  textAreaMaxLengthValidator
                ]}
            textarea
            customClass="col"
            name="description"
            placeholder="Describe your project"
          />
        </div>
      </div>
      <div className="column col-md-4 stepSidebar">
        <Field
          validate={[
                validations.required
              ]}
          name="productionType"
          component={ProductionDropDown}
        />
        <h4>PLEASE SELECT YOUR FILMING DATES</h4>
        <div className="row">
          <div className="col-md-12 col-12">
            <DatePicker validate={validations.required} name="startDate" label="From" />
          </div>
          <div className="col-md-12 col-12">
            <DatePicker validate={[validations.required, validateDates]} name="endDate" label="To" />
          </div>
        </div>
      </div>
      <div className="col col-12">
        <div className="flex row no-gutters actionsStripe">
          <div className="flex col-md-4">
            <Checkbox
              name="includesPermit"
              label="WOULD YOU LIKE TO ISSUE PERMITS FOR THE SELECTED COUNTRY"
            />
          </div>
          <div className="flex col-md-8">
            <div className="flex row justify-content-end">
              <button onClick={handleSubmit(nextStepHandler)} className="nextBtn">PROCEED TO
                    CREW<FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

GeneralInformation.propTypes = {
  nextStepHandler: PropTypes.func,
  handleSubmit: PropTypes.func
};

const mapStateToProps = state => ({
  existingQuote: state.getIn(['wizard', 'quote'])
});

const GeneralInformationTitleComponent = ({ existingQuote }) => (
  <React.Fragment>
    <div className="stepHead text-center row">
      <h1>Project General Information</h1>
    </div>
    {
      existingQuote &&
      <div className="text-center">
        <Link to="/budget">View Saved Quote</Link>
      </div>
    }
  </React.Fragment>
);

GeneralInformationTitleComponent.propTypes = {
  existingQuote: PropTypes.shape()
};

export const GeneralInformationTitle = connect(mapStateToProps)(GeneralInformationTitleComponent);

export default reduxForm({
  form: 'generalInformation',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false
})(withStepHandler(GeneralInformation));
