export const sortServiceCategories = (category1, category2) => {
  const order = [
    'PRODUCTION_CREW',
    'TRAVEL_SUBSISTENCE_HOTEL_AND_OFFICE',
    'EQUIPMENT',
    'ART_DEPARTMENT'
  ];

  return order.indexOf(category1[0]) - order.indexOf(category2[0]);
};
