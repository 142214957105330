import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { stepConfiguration } from '../Wizard/configuration';

const titleForAmount = (currentCategory) => {
  const filteredValues = Object.values(stepConfiguration).flat().filter(val => val.body === currentCategory);
  return filteredValues[0] ? filteredValues[0].titleForAmount : 'PERSONS';
};

const ServiceType = ({
  productionDays, unitPrice, totalCost, quantity, serviceType, currency, serviceCategory
}) => (
  <div className="row budgetTableRow">
    <div className="d-flex col col-md-4 col-12 stepRowHead">
      <h3>{serviceType}</h3>
    </div>
    <div className="d-flex col col-md-8 col-12 quotaCategoryBody">
      <div className="d-flex justify-content-center col-md-3 col-auto quotaCategoryItem">
        <div className="d-md-none d-flex col-8 align-items-center">
          <h2>{titleForAmount(serviceCategory)}</h2>
        </div>
        <div className="d-flex col-md-12 col-4 justify-content-center">
          <span className="budgetPill">{quantity}</span>
        </div>
      </div>
      <div className="d-flex justify-content-center col-md-3 col-auto quotaCategoryItem">
        <div className="d-md-none d-flex col-8 align-items-center">
          <h2>PRODUCTION DAYS</h2>
        </div>
        <div className="d-flex col-md-12 col-4 justify-content-center">
          <span className="budgetPill">{productionDays}</span>
        </div>
      </div>
      <div className="d-flex justify-content-center col-md-3 col-auto quotaCategoryItem">
        <div className="d-md-none d-flex col-7 align-items-center">
          <h2>UNIT PRICE</h2>
        </div>
        <div className="d-flex col-md-12 col-5 justify-content-center">
          <span className="budgetPill">{unitPrice} {currency}</span>
        </div>
      </div>
      <div className="d-flex justify-content-center col-md-3 col-auto quotaCategoryItem">
        <div className="d-md-none d-flex col-7 align-items-center">
          <h2>PRICE</h2>
        </div>
        <div className="d-flex col-md-12 col-5 justify-content-center">
          <span className="budgetPill budgetPricePill">{totalCost} {currency}</span>
        </div>
      </div>
    </div>
  </div>
);

ServiceType.propTypes = {
  currency: PropTypes.string,
  serviceCategory: PropTypes.string,
  productionDays: PropTypes.number,
  unitPrice: PropTypes.number,
  totalCost: PropTypes.number,
  quantity: PropTypes.number,
  serviceType: PropTypes.string
};

export const BudgetCategory = ({
  serviceCategory, serviceTypes, editServiceCategory, currency
}) => (
  <div className="col budgetTable">
    <div className="row budgetTableTitles d-md-flex d-none">
      <div className="d-flex align-items-center col-4" />
      <div className="d-flex col-md-8">
        <div className="d-flex justify-content-center col-3 text-center">
          <h2>{titleForAmount(serviceCategory)}</h2>
        </div>
        <div className="d-flex justify-content-center col-3 text-center">
          <h2>PRODUCTION DAYS</h2>
        </div>
        <div className="d-flex justify-content-center col-3 text-center">
          <h2>UNIT PRICE</h2>
        </div>
        <div className="d-flex justify-content-center col-3 text-center">
          <h2>PRICE</h2>
        </div>
      </div>
    </div>
    <div className="row budgetTableHead no-gutters">
      <div className="col-6"><h2>{serviceCategory}</h2></div>
      <div className="d-flex col-6 align-items-center justify-content-end editBudgetIcon">
        <h3>EDIT</h3>
        <FontAwesomeIcon onClick={() => editServiceCategory(serviceCategory)} icon={faCog} />
      </div>
    </div>
    <div className="col budgetTableContent">
      {
          serviceTypes &&
          serviceTypes.map((serviceType, index) =>
            (<ServiceType
              key={`${serviceType}-${index}`}
              serviceType={serviceType.serviceType}
              serviceCategory={serviceCategory}
              quantity={serviceType.quantity}
              productionDays={serviceType.productionDays}
              unitPrice={serviceType.productionCost}
              totalCost={serviceType.totalCost}
              currency={currency}
            />))
        }
      <div className="row budgetTableTotalRow no-gutters">
        <div className="d-flex justify-content-end col">
          <h2>SUBTOTAL</h2>
        </div>
        <div className="col-md-2 col-5 no-gutters">
          <span className="budgetPill budgetPricePill">
            {
                serviceTypes &&
                serviceTypes.map(serviceType => serviceType.totalCost).reduce((cost1, cost2) => cost1 + cost2)
              } {currency}
          </span>
        </div>
      </div>
    </div>
  </div>
);

BudgetCategory.propTypes = {
  currency: PropTypes.string,
  serviceCategory: PropTypes.string,
  serviceTypes: PropTypes.arrayOf(PropTypes.shape()),
  editServiceCategory: PropTypes.func,
};
