import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { userIsAuthenticatedSelector } from './auth/selectors';
import TermsAndCondition from '../TermsAndCondition/TermsAndCondition';
import Wizard from '../Wizard/Wizard';
import Register from '../Register/Register';
import Login from '../Login/Login';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import BudgetQuote from '../BudgetQuote/BudgetQuote';
import HomePage from '../HomePage/HomePage';
import ContactUs from '../ContactUs/ContactUs';
import ErrorPage from '../ErrorPage/ErrorPage';
import Oauth2WaitingScreen from '../Login/Oauth2WaitingScreen';

const mapStateToProps = state => ({
  isUserAuthenticated: userIsAuthenticatedSelector(state)
});

export const AuthenticatedRoute = connect(mapStateToProps)(({
  component, isUserAuthenticated, exact, path
}) => (
  isUserAuthenticated ?
    <Route
      path={path}
      exact={exact}
      component={component}
    />
    : <Redirect to="/login" />
));

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isUserAuthenticated: PropTypes.bool,
};

export const UnauthenticatedRoute = connect(mapStateToProps)(({
  isUserAuthenticated, redirectOnAuthenticated, component, path, exact
}) => (
  redirectOnAuthenticated && isUserAuthenticated ?
    <Redirect to="/wizard" />
    : <Route
      path={path}
      exact={exact}
      component={component}
    />
));

UnauthenticatedRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.func,
  redirectOnAuthenticated: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool
};

export const REGISTER_PATH = '/register';
export const LOGIN_PATH = '/login';
export const WIZARD_PATH = '/wizard';
export const TERMS_PATH = '/terms';
export const EMAIL_CONFIRMATION_PATH = '/email-confirmation';
export const FACEBOOK_LOGIN_REDIRECT_PATH = '/oauth2-facebook';
export const GOOGLE_LOGIN_REDIRECT_PATH = '/oauth2-google';


export const ApplicationRouter = () => (
  <Switch>
    <UnauthenticatedRoute exact path={REGISTER_PATH} component={Register} />
    <UnauthenticatedRoute exact path="/register-google" component={Register} />
    <UnauthenticatedRoute exact path="/register-facebook" component={Register} />
    <UnauthenticatedRoute exact path="/" component={HomePage} />
    <UnauthenticatedRoute redirectOnAuthenticated exact path={LOGIN_PATH} component={Login} />
    <AuthenticatedRoute exact path={WIZARD_PATH} component={Wizard} />
    <UnauthenticatedRoute exact path={TERMS_PATH} component={TermsAndCondition} />
    <UnauthenticatedRoute exact path={EMAIL_CONFIRMATION_PATH} component={Login} />
    <UnauthenticatedRoute exact path="/forgot-password" component={ForgotPassword} />
    <AuthenticatedRoute exact path="/budget" component={BudgetQuote} />
    <UnauthenticatedRoute exact path="/contact" component={ContactUs} />
    <UnauthenticatedRoute exact path="/errorpage" component={ErrorPage} />
    <UnauthenticatedRoute redirectOnAuthenticated exact path={FACEBOOK_LOGIN_REDIRECT_PATH} component={Oauth2WaitingScreen} />
    <UnauthenticatedRoute redirectOnAuthenticated exact path={GOOGLE_LOGIN_REDIRECT_PATH} component={Oauth2WaitingScreen} />
  </Switch>
);
