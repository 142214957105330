import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './stepperStripe.scss';
import { withStepHandler } from './stepHanlders';

const StepperStripe = ({
  customClass, stepConfiguration, currentWizardStep, setStepHandler, top
}) => (
  <div id={`steps-container-${top ? 'top' : 'bottom'}`} className={['stepperWrapper', 'row', customClass].join(' ')}>
    {
      stepConfiguration.map((step, index) => (
        <div key={`stepper-stripe-${index}`} className={classNames('stepperStepWrapper', 'column', customClass, { active: currentWizardStep >= index })}>
          <div className="stepperStepHead">
            <p>{step.head}</p>
          </div>
          <div className="stepperStepBody">
            {
              currentWizardStep >= index ?
                <div className="d-flex buttonStepWrapper">
                  <div className="col-sm-4 stepMobileIndicator d-md-none d-flex">
                    <p>STEP {index + 1}</p>
                  </div>
                  <button onClick={() => setStepHandler(index)}>
                    {step.body}
                  </button>
                </div> :
                <p>{step.body}</p>
            }
          </div>
        </div>
      ))
    }
  </div>
);

StepperStripe.propTypes = {
  top: PropTypes.bool,
  customClass: PropTypes.string,
  stepConfiguration: PropTypes.shape(),
  currentWizardStep: PropTypes.number,
  setStepHandler: PropTypes.func
};

export default withStepHandler(StepperStripe);
