import React from 'react';
import PropTypes from 'prop-types';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';

const style = {
  fontSize: 12,
  color: '#ffffff',
  width: 188,
  height: 40
};

const Social = ({ googleRedirect, facebookRedirect }) => (
  <div className="row justify-content-center socialContainer">
    <div className="col-6">
      <ul className="social-btns-group">
        <li>
          <GoogleLoginButton onClick={() => window.location.replace(googleRedirect)} style={style} />
        </li>
        <li>
          <FacebookLoginButton onClick={() => window.location.replace(facebookRedirect)} style={style} />
        </li>
      </ul>
    </div>
  </div>
);

Social.propTypes = {
  googleRedirect: PropTypes.string,
  facebookRedirect: PropTypes.string
};

export default Social;
