import React from 'react';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import Subfooter from './Subfooter/Subfooter';
import { ApplicationRouter } from '../core/routing';
import ErrorBoundary from '../ErrorPage/ErrorBoundary';

const Layout = () => (
  <div className="container-fluid">
    <Navbar />
    <ErrorBoundary>
      <ApplicationRouter />
    </ErrorBoundary>
    <Footer />
    <Subfooter />
  </div>
);

export default Layout;
