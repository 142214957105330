import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form/immutable';
import StepsContainer from '../src/components/ProgressWidget/StepsContainer';
import { withStepState } from '../src/components/ProgressWidget/stepHanlders';
import Modal from '../src/components/Modal/Modal';
import { dateDiffInDays } from '../core/utils/dateUtils';
import { getApiRequest } from '../core/utils/requestUtils';
import { stepConfiguration } from './configuration';
import { setProjectAction } from './reducer';
import { productionServicesApiRequest } from './utils';


const initializeProjectWizard = getApiRequest({
  target: 'api/project-manager',
  authorized: true,
  successHandler: (dispatch, response) => {
    dispatch(setProjectAction(response));
    if (response.country) {
      productionServicesApiRequest(response.country)(null, dispatch);
    }
  }
});

export const initializeAvailableCountries = getApiRequest({
  target: 'api/project-manager/countries',
  authorized: true,
  successHandler: (dispatch, response) => {
    dispatch({ type: 'INITIALIZE_COUNTRIES', payload: response });
  }
});

export const initializeExistingQuote = getApiRequest({
  target: 'api/project-manager/quota',
  authorized: true,
  successHandler: (dispatch, response) => {
    dispatch({ type: 'SET_QUOTE', payload: response });
  }
});

const mapStateToProps = state => ({
  project: state.getIn(['wizard', 'project']),
  projectId: formValueSelector('generalInformation')(state, 'id'),
  country: formValueSelector('generalInformation')(state, 'country'),
  differenceInDays: dateDiffInDays(
    new Date(formValueSelector('generalInformation')(state, 'startDate')),
    new Date(formValueSelector('generalInformation')(state, 'endDate'))
  )
});

const mapDispatchToProps = dispatch => ({
  initializeProjectValues: () => {
    initializeProjectWizard(null, dispatch);
    initializeAvailableCountries(null, dispatch);
    initializeExistingQuote(null, dispatch);
  }
});


class Wizard extends React.Component {
  componentWillMount() {
    this.props.initializeProjectValues();
  }

  render() {
    const {
      Component, titleComponent, numberOfCols, titleForAmount, titleForType, nextStepHandler, nextStepText, state, api
    } = stepConfiguration[this.props.currentWizardStep];

    return (
      <StepsContainer TitleComponent={titleComponent}>
        <Component
          nextStepHandler={nextStepHandler}
          serviceCategory={api}
          titleForAmount={titleForAmount}
          titleForType={titleForType}
          numberOfCols={numberOfCols}
          currentWizardStep={this.props.currentWizardStep}
          nextStepText={nextStepText}
          initialValues={this.props.project && this.props.project[state]}
        />
        <Modal modalName="WIZARD_LOADING_MODAL">
          <h5>Please wait, your project is being updated.</h5>
        </Modal>
      </StepsContainer>
    );
  }
}

Wizard.propTypes = {
  currentWizardStep: PropTypes.number,
  initializeProjectValues: PropTypes.func,
  project: PropTypes.shape()
};

export default withStepState(connect(mapStateToProps, mapDispatchToProps)(Wizard));
