import React from 'react';
import { Link } from 'react-router-dom';
import './errorpage.scss';
import CatError from '../src/assets/images/cat-error.png';

const ErrorPage = () => (
  <div className="container">
    <div className="col justify-content-center erroPageWrapper">
      <img src={CatError} alt="cat404" />
      <h1>Error Message Title</h1>
      <p>Error description</p>
      <div className="col-md-3 col">
        <Link className="action-btn" to="/login">Go Home</Link>
      </div>
    </div>
  </div>
);

export default ErrorPage;
