import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { parse } from 'query-string';
import { reduxForm } from 'redux-form/immutable';
import { TopLogo, InFormLogo } from '../src/components/images';
import { Input } from '../src/components';
import './forgotpassword.scss';
import ForgotPasswordModal, { FORGOT_PASSWORD_MODAL } from './ForgotPasswordModal';
import validations from '../src/components/ReduxForm/validations';
import { setModalStateAction } from '../src/components/Modal/actions';
import { modalStatuses } from '../src/components/Modal/constants';
import { postApiRequest } from '../core/utils/requestUtils';

const maxLengthValidator = validations.maxLength(40);
const passwordMinLengthValidator = validations.minLength(8);
const passwordConfirmValidator = validations.fieldMustMatch('newPassword');

const addHashToValueMap = hash => (immutableValues) => {
  const { newPassword, newPasswordConfirm } = immutableValues.toJS();
  return {
    hash,
    newPassword,
    newPasswordConfirm
  };
};

const submitForgotPassword = hash => postApiRequest({
  target: `api/password-manager/${hash ? 'confirm' : 'request'}`,
  beforeRequestHandler: dispatch => dispatch(setModalStateAction({ openModalName: FORGOT_PASSWORD_MODAL, modalStatus: modalStatuses.LOADING })),
  successHandler: dispatch => dispatch(setModalStateAction({ openModalName: FORGOT_PASSWORD_MODAL, modalStatus: modalStatuses.SUCCESS })),
  errorHandler: dispatch => dispatch(setModalStateAction({ openModalName: FORGOT_PASSWORD_MODAL, modalStatus: modalStatuses.FAILED })),
  transformValues: hash ? addHashToValueMap(hash) : undefined
});

const requestResetFields = () => (
  <div className="row justify-content-center">
    <div className="col-10 inputWrapper">
      <Input
        name="email"
        customClass="inputGeneral"
        type="email"
        placeholder="Enter your e-mail"
        label="E-MAIL"
        validate={[validations.required, validations.email]}
      />
    </div>
  </div>
);

const confirmResetFields = () => (
  <React.Fragment>
    <div className="row justify-content-center">
      <div className="col-10 inputWrapper">
        <Input
          name="newPassword"
          customClass="inputGeneral"
          type="password"
          placeholder="Enter your new password"
          label="New password"
          validate={[validations.required, passwordMinLengthValidator, maxLengthValidator]}
        />
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-10 inputWrapper">
        <Input
          name="newPasswordConfirm"
          customClass="inputGeneral"
          type="password"
          placeholder="Confirm your new password"
          label="New password confirmation"
          validate={[validations.required, passwordMinLengthValidator, maxLengthValidator, passwordConfirmValidator]}
        />
      </div>
    </div>
  </React.Fragment>
);


const ForgotPassword = ({ handleSubmit, location: { search } }) => {
  const { hash } = parse(search);

  return (
    <div className="contentWrapper darkPageBg fpassFormWrapper">
      <form onSubmit={handleSubmit(submitForgotPassword(hash))}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="formWrapper col-md-5 col-sm-8 col">
              <div className="row formHeader">
                <div className="col-8 fpassTitle">
                  <p className="signUpTitle">FORGOT MY PASSWORD</p>
                </div>
                <div className="col-4 text-right topLogoFpass">
                  <img src={TopLogo} alt="logo" />
                </div>
              </div>
              <div className="row justify-content-center registerWrapper">
                <div onSubmit={handleSubmit(submitForgotPassword)} className="flex col">
                  <div className="row justify-content-center inFormLogo">
                    <img src={InFormLogo} alt="logo" />
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-10 inputWrapper fPasswording">
                      {
                        hash
                          ? 'Please enter your new password'
                          : 'Please enter your e-mail address in order to receive an e-mail with the recovery instructions'
                      }
                    </div>
                  </div>

                  {
                    hash
                      ? confirmResetFields()
                      : requestResetFields()
                  }

                </div>
              </div>

              <div className="justify-content-center text-center captchWrapper" />

              <div className="row justify-content-center text-center">
                <div className="col-10">
                  <button type="submit" className="action-btn">SUBMIT</button>
                </div>
              </div>

              <div className="row justify-content-center mrgBottom">
                <div className="row">
                  <div className="col-12 forgotPass">
                    <Link to="/login">Back to login page</Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
      <ForgotPasswordModal />
    </div>
  );
};

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func,
  location: PropTypes.shape()
};

export default reduxForm({
  form: 'forgotpassword'
})(withRouter(ForgotPassword));
