import React from 'react';
import { Link } from 'react-router-dom';
import LaptopHome from '../src/assets/images/laptopHome.png';
import WizardStep from '../src/assets/images/wizardStep.png';
import imageGallery from '../src/assets/images/imageGallery.jpg';
import PreFooterLogo from '../src/assets/images/preFooterLogo.png';
import './homepage.scss';

const HomePage = () => (
  <div className="col homeWrapper">
    <div className="row homeHeadImage">
      <div className="container">
        <div className="row">
          <div className="col col-md-5 col-sm-12">
            <h1>Ready to budget your project in 3 simple steps?</h1>
            <p>With over 40 specialties and equipment options to choose from, in over 100 countries, create an accurate budget for your production in no-time. Go ahead, try it!</p>
            <button className="action-btn"><Link to="/wizard">CREATE YOUR BUDGET NOW</Link></button>
          </div>
          {/* <div className="col col-md-7 col-sm-12 align-items-center justify-content-center"> */}
          {/* <iframe title="iframe-centre" width="360" height="215" style={{ display: 'flex' }} src="https://www.youtube.com/embed/zLhoDB-ORLQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> */}
          {/* /!* <video src=""></video> *!/ */}
          {/* </div> */}
        </div>
      </div>
    </div>
    <div className="row aboutWrapper">
      <div className="container align-items-center justify-content-center aboutContainer">
        <div className="col col-sm-10 text-center align-items-center">
          <h2>ABOUT</h2>
          <p>NEEDaFIXER is a global production house operating in over 100 countries globally, with the largest network of film professionals in the industry.<br /><br />
            Our worldwide presence combined with our local expertise, allowed us to create a budgeting tool that is here to revolutionize the budgeting process, saving you time and hassle.<br /><br />
            Our budgeting app is free to use, and that includes everyone. Whether you are a filmmaker, a producer or just someone with a great idea, budget away!<br /><br />
            With real market rates for a wide variety of roles and equipment (as well as travel and accommodation costs), all around the world, we can 100% guarantee the accuracy of our tool.<br /><br />
            Through our simplified 3-step process, you can easily calculate the projected cost of your production -no matter how big or small- within just minutes!
          </p>
        </div>
      </div>
    </div>
    <div className="row featuresWrapper">
      <div className="container">
        <div className="row justify-content-center">
          <h1>FEATURES</h1>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-9">
            <img src={LaptopHome} alt="laptophome" />
          </div>
          <div className="col-sm-12 col-md-3 justify-content-center featureInfoCol">
            <h1>CREATE<br />YOUR PROJECT</h1>
            <p>Create your project
              What is the title of your production?
              Is it a TVC, a music video, a commercial or sth else god damn it?
              Give your project a title and a short description, then fill in the filming dates and you’re ready to go!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row crewHomeWrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <h2>SELECT YOUR CREW, EQUIPMENT, ART DEPARTMENT, TRAVEL, SUBSISTENCE</h2>
            <p>This is where the fun begins.
              Select the number of people and/or services you will need from the list.
              Rome wasn’t built in a day, so don’t forget to fill in the number of days required for pre-production and production.
              If you made a mistake don’t stress, you can always go back and add/remove things from your budget.
            </p>
          </div>
          <div className="col-sm-12 col-md-7 crewHomeCol">
            <img src={WizardStep} alt="wizardstep" />
          </div>
        </div>
      </div>
    </div>
    <div className="row calculateWrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <h1>CALCULATE YOUR BUDGET</h1>
            <p>
              Simply press “Enter”! The app will generate a PDF of your budget, which you can then download in your computer.
              Don’t feel like downloading yet? No problem! The app saves a copy of your budget online, which you can always access and tweak.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row oddBtn">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-3">
            <button className="action-btn"><Link to="/wizard">CREATE YOUR BUDGET NOW</Link></button>
          </div>
        </div>
      </div>
    </div>
    <div className="row imageGallery">
      <div className="col">
        <img src={imageGallery} alt="imagegallery" />
      </div>
    </div>
    <div className="row preFooter">
      <div className="container align-items-center justify-content-center preFooterContainer">
        <div className="col col-sm-10 text-center align-items-center">
          <img src={PreFooterLogo} alt="prefooterlogo" />
          <p>
            NEEDaFIXER is the global production, service and fixing house and your main point of contact for productions all over the world.
            We’re powered by the NEEDaFIXER Community – the largest international network of film directors, producers, fixers, location managers, crew and journalists. Our members operate in more than 100 countries.
          </p>
          <p>
            Get the best service in the industry at competitive rates. That’s a promise!

            Whether you’re filming from out of town or shooting in a different continent, you can benefit from our global support.

            Unlike many other agencies, we’re not here just to score a commission. We arrange everything for you.
            And no job is too big or too small for us!
          </p>
          <p>
            Looking for local knowledge about film permits? Check. Need full production services for your award-winning video? No problem.

            As an extension of your production team, we make it happen!
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default HomePage;
