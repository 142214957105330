import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss';


class CustomPicker extends React.Component {
  render() {
    const { onClick, value } = this.props;
    const date = value ? moment(value) : null;
    const startDate = date ? date.format('DD') : '-';
    const startMonth = date ? date.format('MM') : '-';
    const startYear = date ? date.format('YYYY') : '-';

    return (
      <div className="dateOverlap" onClick={onClick}>
        <p>{startDate}</p>
        <span>|</span>
        <p>{startMonth}</p>
        <span>|</span>
        <p>{startYear}</p>
      </div>
    );
  }
}

CustomPicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

const DatePickerComponent = ({
  input, meta: { touched, error }, label
}) => (
  <div className="datePicker column">
    <label>{label}</label>
    <div className="datePickerWrapper">
      <DatePicker {...input} dateForm="MM/DD/YYYY" selected={input.value ? moment(input.value) : null} customInput={<CustomPicker />} />
    </div>
    {touched && error && <span className="errorMsg">{error}</span>}
  </div>
);

DatePickerComponent.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  label: PropTypes.string
};

const DatePickerField = props => (
  <Field
    parse={value => (value ? value.toDate() : undefined)}
    component={DatePickerComponent}
    {...props}
  />
);


export default DatePickerField;
