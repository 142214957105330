import buildUrl from 'build-url';
import { getApiRequest } from '../core/utils/requestUtils';
import { setSpinnerActiveAction, setSpinnerInactiveAction } from '../src/components/Spinner/reducer';

const requestUrl = country => buildUrl({
  path: '/api/project-manager/production-services/types',
  queryParams: {
    country
  }
});

export const productionServicesApiRequest = country => getApiRequest({
  authorized: true,
  target: requestUrl(country),
  successHandler: (dispatch, data) => {
    dispatch({ type: 'INITIALIZE_PRODUCTION_SERVICES', payload: data });
    dispatch(setSpinnerInactiveAction());
  },
  beforeRequestHandler: dispatch => dispatch(setSpinnerActiveAction()),
  errorHandler: dispatch => dispatch(setSpinnerInactiveAction())
});

const productionServicePayload = ({
  productionDays, preproductionDays, numberOfPeople, serviceType
}) => ({
  productionDays,
  preproductionDays,
  numberOfPeople,
  serviceType,
});

export const transformValues = (serviceCategory, projectId) => (immutableValues) => {
  const values = immutableValues && immutableValues.toJS();
  const serviceDetailsList = Object.entries(values).map((key) => {
    const [productionServiceName, productionServiceValue] = key;
    const { numberOfPersons, productionDays, preProductionDays } = productionServiceValue;
    return productionServicePayload({
      productionDays, preproductionDays: preProductionDays, numberOfPeople: numberOfPersons, serviceType: productionServiceName
    });
  });
  return {
    projectId,
    serviceCategory,
    serviceDetailsList
  };
};

export const transformProductionServices = (projectProductionServices) => {
  const productionCrew = {};
  const artDepartment = {};
  const subsistence = {};
  const equipment = {};

  if (!projectProductionServices) {
    return {
      productionCrew,
      artDepartment,
      subsistence,
      equipment
    };
  }

  projectProductionServices
    .filter(productionService => productionService.productionService.serviceCategory === 'PRODUCTION_CREW')
    .forEach(((productionService) => {
      productionCrew[productionService.productionService.serviceType] = {
        numberOfPersons: productionService.numberOfPeople,
        productionDays: productionService.productionDays,
        preProductionDays: productionService.preproductionDays
      };
    }));
  projectProductionServices
    .filter(productionService => productionService.productionService.serviceCategory === 'ART_DEPARTMENT')
    .forEach(((productionService) => {
      artDepartment[productionService.productionService.serviceType] = {
        numberOfPersons: productionService.numberOfPeople,
        productionDays: productionService.productionDays,
        preProductionDays: productionService.preproductionDays
      };
    }));
  projectProductionServices
    .filter(productionService => productionService.productionService.serviceCategory === 'TRAVEL_SUBSISTENCE_HOTEL_AND_OFFICE')
    .forEach(((productionService) => {
      subsistence[productionService.productionService.serviceType] = {
        numberOfPersons: productionService.numberOfPeople,
        productionDays: productionService.productionDays,
        preProductionDays: productionService.preproductionDays
      };
    }));
  projectProductionServices
    .filter(productionService => productionService.productionService.serviceCategory === 'EQUIPMENT')
    .forEach(((productionService) => {
      equipment[productionService.productionService.serviceType] = {
        numberOfPersons: productionService.numberOfPeople,
        productionDays: productionService.productionDays,
        preProductionDays: productionService.preproductionDays
      };
    }));
  return {
    productionCrew,
    artDepartment,
    subsistence,
    equipment
  };
};

export const rowStyle = {
  userSelect: 'none',
  background: 'transparent',
  alignItems: 'center',
  padding: '15px 5px',
  margin: '0px'
};
