import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from '../src/components/Modal/Modal';
import { modalStatusSelector } from '../src/components/Modal/selector';
import { modalStatuses } from '../src/components/Modal/constants';

export const CONFIRM_EMAIL_MODAL = 'CONFIRM_EMAIL_MODAL';

const modalContent = {
  [modalStatuses.LOADING]: {
    title1: 'Validating confirmation e-mail. ',
    title2: 'Please wait...'
  },
  [modalStatuses.SUCCESS]: {
    title1: 'Your account has been activated',
    title2: 'You can now create your first budget!'
  },
  [modalStatuses.FAILED]: {
    title1: 'There was a problem processing your request. Please try again',
    title2: 'If the problem persists, please contact us.'
  }
};

const mapStateToProps = state => ({
  modalStatus: modalStatusSelector(state)
});

const ConfirmEmailModal = ({ modalStatus }) => (
  <Modal
    modalName={CONFIRM_EMAIL_MODAL}
  >
    {
      modalStatus &&
      <React.Fragment>
        <h4>{modalContent[modalStatus].title1}</h4>
        <br />
        <h5>{modalContent[modalStatus].title2}</h5>
        <br />
      </React.Fragment>
    }
  </Modal>
);

ConfirmEmailModal.propTypes = {
  modalStatus: PropTypes.string
};

export default connect(mapStateToProps)(withRouter(ConfirmEmailModal));
