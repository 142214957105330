import GeneralInformation, { GeneralInformationTitle } from './GeneralInformation/GeneralInformation';
import { ProductionServiceTitle } from './ProductionServices/ProductionServiceTitle';
import { finalStepNextStepHandler, initialStepNextStepHandler, intermediateStepNextStepHandler } from './stepHandlers';
import TermsAndCondition from '../TermsAndCondition/TermsAndCondition';
import { ART_DEPARTMENT, EQUIPMENT, PRODUCTION_CREW, SUBSISTENCE } from './ProductionServices/serviceCategories';

// const FINAL_STEP = 6;

export const stepConfiguration = [
  {
    titleComponent: GeneralInformationTitle,
    body: 'GENERAL INFORMATION',
    Component: GeneralInformation,
    numberOfCols: 2,
    nextStepHandler: initialStepNextStepHandler,
    nextStepText: `PROCEED TO ${PRODUCTION_CREW.title}`,
    state: 'generalInformation'
  },
  {
    titleComponent: ProductionServiceTitle,
    body: PRODUCTION_CREW.title,
    api: PRODUCTION_CREW.api,
    Component: PRODUCTION_CREW.component,
    numberOfCols: 2,
    titleForAmount: 'Number Of Persons'.toUpperCase(),
    titleForType: 'Role'.toUpperCase(),
    nextStepHandler: intermediateStepNextStepHandler,
    nextStepText: `PROCEED TO ${EQUIPMENT.title}`,
    state: 'productionCrew'
  },
  {
    titleComponent: ProductionServiceTitle,
    body: EQUIPMENT.title,
    api: EQUIPMENT.api,
    Component: EQUIPMENT.component,
    numberOfCols: 2,
    titleForAmount: 'Quantity'.toUpperCase(),
    titleForType: 'Item'.toUpperCase(),
    nextStepHandler: intermediateStepNextStepHandler,
    nextStepText: `PROCEED TO ${SUBSISTENCE.title}`,
    state: 'equipment'
  },
  {
    titleComponent: ProductionServiceTitle,
    body: SUBSISTENCE.title,
    api: SUBSISTENCE.api,
    Component: SUBSISTENCE.component,
    numberOfCols: 2,
    titleForAmount: 'Persons'.toUpperCase(),
    titleForType: 'Travel, Subsistence, Hotel and Office:'.toUpperCase(),
    nextStepHandler: intermediateStepNextStepHandler,
    nextStepText: `PROCEED TO ${ART_DEPARTMENT.title}`,
    state: 'subsistence'
  },
  {
    titleComponent: ProductionServiceTitle,
    body: ART_DEPARTMENT.title,
    api: ART_DEPARTMENT.api,
    Component: ART_DEPARTMENT.component,
    numberOfCols: 2,
    titleForAmount: 'Number of Persons'.toUpperCase(),
    titleForType: 'Department'.toUpperCase(),
    nextStepHandler: intermediateStepNextStepHandler,
    nextStepText: 'PROCEED TO TERMS',
    state: 'artDepartment'
  },
  {
    titleComponent: ProductionServiceTitle,
    body: 'TERMS AND CONDITIONS',
    Component: TermsAndCondition,
    numberOfCols: 2,
    nextStepHandler: finalStepNextStepHandler,
  }];

export const getStepByServiceCategory = (serviceCategory) => {
  switch (serviceCategory) {
    case PRODUCTION_CREW.title:
      return 1;
    case EQUIPMENT.title:
      return 2;
    case SUBSISTENCE.title:
      return 3;
    case ART_DEPARTMENT.title:
      return 4;
    default:
      return 1;
  }
};
