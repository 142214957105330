/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './errorpage.scss';
import CatError from '../src/assets/images/cat-error.png';
import { clearErrorAction, setErrorAction } from './actions';
import { errorSelector } from './selectors';
import { isEmptyImmutableMap } from '../core/utils/langUtils';
import authTokenHandler from '../core/auth/authTokenHandler';
import sendErrorLog from './sendErrorLog';

const mapStateToProps = state => ({
  applicationError: errorSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setError: (error, info) => {
    const stacktrace = `${error.toString()} ${info.componentStack}`;
    dispatch(setErrorAction(info));
    sendErrorLog({ url: window.location.href, stacktrace });
  },
  handleReturnHome: (history) => {
    authTokenHandler.clearAuthToken();
    dispatch({ type: 'CLEAR_STATE' });
    history.push('/');
    dispatch(clearErrorAction());
  }
});

class ErrorBoundary extends Component {
  componentDidCatch(error, info) { this.props.setError(error, info); }
  render() {
    if (!isEmptyImmutableMap(this.props.applicationError)) {
      return (
        <div className="container">
          <div className="col justify-content-center erroPageWrapper">
            <img src={CatError} alt="cat404" />
            <h1>An Error Occurred</h1>
            <p>if the problem persists please contact us</p>
            <div className="col-md-3 col">
              <button
                onClick={() => this.props.handleReturnHome(this.props.history)}
                className="action-btn"
              >
                HOME
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  setError: PropTypes.func,
  handleReturnHome: PropTypes.func,
  applicationError: PropTypes.shape(),
  history: PropTypes.shape()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorBoundary));
