import { Map } from 'immutable';

export const progressWidgetActionTypes = {
  SET_PROGRESS_STEP: 'set-progress-step',
  CLEAR_PROGRESS_STATE: 'clear-progress-state'
};

export const progressWidgetReducer = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case progressWidgetActionTypes.SET_PROGRESS_STEP:
      return state.set('step', action.payload);
    case progressWidgetActionTypes.CLEAR_PROGRESS_STATE:
      return Map();
    default:
      return state;
  }
};
