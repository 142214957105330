import React from 'react';
import PropTypes from 'prop-types';
import './errorMessage.scss';

const ErrorMessage = ({ error }) => (
  <span className="errorMessage">
    {error}
  </span>
);

ErrorMessage.propTypes = {
  error: PropTypes.string
};

export default ErrorMessage;
