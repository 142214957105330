import { postApiRequest } from '../core/utils/requestUtils';
import { setProgressStepAction } from '../src/components/ProgressWidget/actions';
import { transformValues } from './utils';
import { setSpinnerActiveAction, setSpinnerInactiveAction } from '../src/components/Spinner/reducer';
import { setProjectAction } from './reducer';
import { modalState, setModalStateAction } from '../src/components/Modal/actions';
import { modalStatuses } from '../src/components/Modal/constants';

export const initialStepNextStepHandler = postApiRequest({
  target: 'api/project-manager/general-information',
  beforeRequestHandler: dispatch => dispatch(setSpinnerActiveAction()),
  successHandler: (dispatch, response) => {
    dispatch(setProjectAction(response));
    dispatch(setProgressStepAction(1));
    dispatch(setSpinnerInactiveAction());
  },
  authorized: true,
  errorHandler: dispatch => dispatch(setSpinnerInactiveAction()),
});


export const intermediateStepNextStepHandler = (currentWizardStep, serviceCategory, projectId) => postApiRequest({
  target: 'api/project-manager/production-services',
  successHandler: (dispatch, response) => {
    dispatch(setProjectAction(response));
    dispatch(setProgressStepAction(currentWizardStep + 1));
    dispatch(setSpinnerInactiveAction());
  },
  beforeRequestHandler: (dispatch) => {
    dispatch(setSpinnerActiveAction());
  },
  authorized: true,
  transformValues: transformValues(serviceCategory, projectId),
  errorHandler: (dispatch) => {
    dispatch(setSpinnerInactiveAction());
  }
});

export const finalStepNextStepHandler = (currentWizardStep, serviceCategory, projectId, history) => postApiRequest({
  target: '/api/project-manager/quota',
  authorized: true,
  successHandler: (dispatch, response) => {
    dispatch({ type: 'SET_QUOTE', payload: response });
    history.push('/budget');
    dispatch(setSpinnerInactiveAction());
  },
  beforeRequestHandler: (dispatch) => {
    dispatch(setSpinnerActiveAction());
  },
  errorHandler: (dispatch) => {
    dispatch(setSpinnerInactiveAction());
    dispatch(setModalStateAction(modalState('REQUEST_BUDGET_MODAL', modalStatuses.FAILED)));
  }
});

