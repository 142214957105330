import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { modalStatusSelector } from '../../src/components/Modal/selector';
import Modal from '../../src/components/Modal/Modal';

export const CHANGE_COUNTRY_WARNING_MODAL = 'CHANGE_COUNTRY_WARNING_MODAL';

const mapStateToProps = state => ({
  modalStatus: modalStatusSelector(state)
});

const ChangeCountryWarningModal = ({ onCloseModal }) => (
  <Modal
    onCloseModal={onCloseModal}
    modalName={CHANGE_COUNTRY_WARNING_MODAL}
    twoButtons
  >
    <h1>Changing the country of a project will result in losing all progress</h1>
    <h5>Do you want to continue?</h5>
  </Modal>
);

ChangeCountryWarningModal.propTypes = {
  onCloseModal: PropTypes.func
};

export default connect(mapStateToProps)(withRouter(ChangeCountryWarningModal));
