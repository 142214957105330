import { Map } from 'immutable';
import { transformProductionServices } from './utils';

const SET_PROJECT = 'SET_PROJECT';

const getGeneralInformation = payload => ({
  id: payload.id,
  includesPermit: payload.includesPermit,
  startDate: payload.startDate,
  endDate: payload.endDate,
  productionType: payload.productionType,
  country: payload.country,
  title: payload.title,
  description: payload.description
});

export const setProjectAction = payload => ({
  type: SET_PROJECT,
  payload: {
    generalInformation: getGeneralInformation(payload),
    ...transformProductionServices(payload.projectProductionServices)
  }
});

const transformQuoteElements = (quote) => {
  const serviceCategories = new Set(quote.quoteElements.map(quoteElement => quoteElement.serviceCategory));

  const productionServices = {};
  serviceCategories.forEach((serviceCategory) => {
    productionServices[serviceCategory] =
      quote.quoteElements.filter(quoteElement => quoteElement.serviceCategory === serviceCategory);
  });
  return {
    id: quote.id,
    title: quote.title,
    country: quote.country,
    includesPermit: quote.includesPermit,
    startDate: quote.startDate,
    endDate: quote.endDate,
    description: quote.description,
    targetProject: quote.targetProject,
    totalCost: quote.totalCost,
    createdDate: quote.createdDate,
    insuranceFeeCost: quote.insuranceFeeCost,
    productionFeeCost: quote.productionFeeCost,
    currency: quote.currency,
    productionServices
  };
};

export const wizardReducer = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case 'INITIALIZE_PRODUCTION_SERVICES':
      return state.set('productionServices', Map(action.payload));
    case 'SET_QUOTE':
      return state.set('quote', transformQuoteElements(action.payload));
    case 'INITIALIZE_COUNTRIES':
      return state.set('countries', action.payload);
    case 'SET_PROJECT':
      return state
        .set('project', action.payload);
    default:
      return state;
  }
};

