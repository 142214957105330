import { takeLatest, all, call, put } from 'redux-saga/effects';
import { socialLoginRequest } from '../core/utils/restClient';
import { SET_AUTH } from '../core/auth/reducer';
import { getGlobalHistory } from '../core/app';
import { setSpinnerActiveAction, setSpinnerInactiveAction } from '../src/components/Spinner/reducer';
import { modalState, setModalStateAction } from '../src/components/Modal/actions';
import { modalStatuses } from '../src/components/Modal/constants';

function* loginWithSocialSaga(action) {
  try {
    yield put(setSpinnerActiveAction());
    yield call(
      socialLoginRequest,
      action.payload.code,
      action.payload.redirect,
      action.payload.provider
    );
    yield put({ type: SET_AUTH });
  } catch (e) {
    yield put(setModalStateAction(modalState('LOGIN_WITH_SOCIAL_MODAL', modalStatuses.FAILED)));
  } finally {
    yield put(setSpinnerInactiveAction());
    getGlobalHistory().push('/login');
  }
}

export default function* loginWithGoogleWatcher() {
  yield all([
    takeLatest('LOGIN_WITH_SOCIAL', loginWithSocialSaga)
  ]);
}
