import React from 'react';
import './subfooter.scss';

const Subfooter = () => (
  <div className="subFooterWrapper">
    <div className="container">
      <div className="row justify-content-center">
        <p>&copy; 2018 All rights reserved to NEEDaFIXER Ltd.</p>
      </div>
    </div>
  </div>
);

export default Subfooter;
