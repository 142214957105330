import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import './contactus.scss';
import Input from '../src/components/ReduxForm/fields/Input/Input';

const ContactUs = () => (
  <div className="col contactUsWrapper contentWrapper">
    <div className="container">
      <div className="formWrapper col-md-5">
        <h1>CONTACT US</h1>
        <div className="inputWrapper">
          <Input
            name="name"
            customClass="inputGeneral inpuWithIcon"
            type="text"
            placeholder="Type your name"
            label="NAME"
          />
        </div>
        <div className="inputWrapper">
          <Input
            name="email"
            customClass="inputGeneral inpuWithIcon"
            type="email"
            placeholder="Type your email"
            label="EMAIL"
          />
        </div>
        <div className="inputWrapper">
          <Input
            textarea
            name="PROJECT DESCRIPTION"
            customClass="inputGeneral inpuWithIcon"
            type="text"
            placeholder="Type your message"
            label="PROJECT DESCRIPTION"
          />
        </div>
        <div className="row col-12 justify-content-center text-center">
          <div className="col-10">
            <button
              type="submit"
              className="action-btn"
            >
            SUMBIT
            </button>
          </div>
        </div>
        {/* PUT THE CAPTCHA HERE */}
      </div>
    </div>
  </div>
);


export default reduxForm({
  form: 'coctactUs'
})(withRouter(connect(null, null)(ContactUs)));
