import { Map } from 'immutable';

export const registerReducer = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case 'SET_USER_INFO':
      return state.set('userInfo', action.payload);
    default:
      return state;
  }
};
