export const SET_MODAL_STATE = 'set-modal-state';
export const CLOSE_MODAL = 'close-modal';

export const modalState = (openModalName, modalStatus) => ({
  openModalName,
  modalStatus
});

export const setModalStateAction = ({ openModalName, modalStatus }) => ({
  type: SET_MODAL_STATE,
  payload: {
    openModalName,
    modalStatus
  }
});

export const closeModalAction = () => ({
  type: CLOSE_MODAL
});
