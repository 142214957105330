import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets';
import { initialize, formValueSelector, change } from 'redux-form/lib/immutable';
import { modalState, setModalStateAction } from '../../src/components/Modal/actions';
import ChangeCountryWarningModal, { CHANGE_COUNTRY_WARNING_MODAL } from './ChangeCountryWarningModal';
import { modalStatuses } from '../../src/components/Modal/constants';
import { productionServicesApiRequest } from '../utils';

const mapStateToProps = state => ({
  countries: state.getIn(['wizard', 'countries']),
  currentCountry: formValueSelector('generalInformation')(state, 'country'),
});

const mapDispatchToProps = dispatch => ({
  openChangeCountryWarningModal: () => dispatch(setModalStateAction(modalState(CHANGE_COUNTRY_WARNING_MODAL, modalStatuses.NOTIFICATION))),
  initializeProductionServices: country => productionServicesApiRequest(country)(null, dispatch)
});

let targetValue;

const onCloseModal = (dispatch) => {
  dispatch(change('generalInformation', 'country', targetValue));
  dispatch(initialize('productionCrew', {}, false));
  dispatch(initialize('subsistence', {}, false));
  dispatch(initialize('equipment', {}, false));
  dispatch(initialize('artDepartment', {}, false));
  productionServicesApiRequest(targetValue)(null, dispatch);
};

const CountriesDropDown = ({
  input, meta, countries, currentCountry, openChangeCountryWarningModal, initializeProductionServices
}) => (
  <div className="inputWrap">
    <label>COUNTRY</label>
    <DropdownList
      {...input}
      onBlur={() => undefined}
      onChange={(value) => {
        targetValue = value;
        if (currentCountry) {
          openChangeCountryWarningModal();
        } else {
          initializeProductionServices(value);
          input.onChange(value);
        }
      }}
      id="edit-exp-role-select"
      filter="contains"
      data={countries}
      textField="label"
      valueField="value"
      placeholder="Please select country"

    />
    <ChangeCountryWarningModal onCloseModal={onCloseModal} />
    <span className="errorMsg">{meta.touched && meta.error && meta.error}</span>
  </div>
);

CountriesDropDown.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  countries: PropTypes.arrayOf(PropTypes.string),
  currentCountry: PropTypes.string,
  openChangeCountryWarningModal: PropTypes.func,
  initializeProductionServices: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CountriesDropDown);
