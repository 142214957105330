import { Map } from 'immutable';

export const setSpinnerActiveAction = () => ({
  type: 'SET_SPINNER_ACTIVE'
});

export const setSpinnerInactiveAction = () => ({
  type: 'SET_SPINNER_INACTIVE'
});

export const spinnerReducer = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case 'SET_SPINNER_ACTIVE':
      return state.set('active', true);
    case 'SET_SPINNER_INACTIVE':
      return state.set('active', false);
    default:
      return state;
  }
};
