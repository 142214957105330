import React from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';


// FIXME: Request production type from backend
const productionTypes = [
  {
    code: 'TV_SERIES',
    name: 'TV Series'
  },
  {
    code: 'TVC',
    name: 'TVC'
  },
  {
    code: 'CORPORATE',
    name: 'Corporate Video'
  },
  {
    code: 'MUSIC_VIDEO',
    name: 'Music Video'
  },
  {
    code: 'BRAND_CONTENT',
    name: 'Brand Content'
  },
  {
    code: 'ONLINE_CONTENT',
    name: 'Online Content'
  },
  {
    code: 'DOCUMENTARY',
    name: 'Documentary'
  },
  {
    code: 'NEWS',
    name: 'News'
  },
  {
    code: 'FEATURE_FILM',
    name: 'Feature Film'
  }
];

const ProductionDropDown = ({ input, meta }) => (
  <div className="inputWrap">
    <label>TYPE OF PRODUCTION</label>
    <DropdownList
      {...input}
      onBlur={() => undefined}
      id="edit-exp-role-select"
      filter="contains"
      data={productionTypes}
      textField="name"
      valueField="code"
      onChange={value => input.onChange(value.code)}
      placeholder="Please select production type"
    />
    <span className="errorMsg">{meta.touched && meta.error && meta.error}</span>
  </div>
);


ProductionDropDown.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  countries: PropTypes.arrayOf(PropTypes.string)
};

export default ProductionDropDown;
