import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../src/components/Modal/Modal';
import { modalStatusSelector } from '../src/components/Modal/selector';
import { modalStatuses } from '../src/components/Modal/constants';

export const REQUEST_BUDGET_MODAL = 'REQUEST_BUDGET_MODAL';

const modalContent = {
  [modalStatuses.LOADING]: {
    title1: 'You request is being processed.',
    title2: 'Please wait...'
  },
  [modalStatuses.SUCCESS]: {
    title1: 'Your request has been submitted successfully.',
    title2: 'It is now safe to close this window.'
  },
  [modalStatuses.FAILED]: {
    title1: 'You have exceeded the maximum number of Budgets you can generate in a day.',
    title2: 'Please try again tomorrow or contact budget@needafixer.com to increase your limit.'
  }
};

const mapStateToProps = state => ({
  modalStatus: modalStatusSelector(state)
});

const RequestBudgetModal = ({ modalStatus }) => (
  <Modal
    modalName={REQUEST_BUDGET_MODAL}
  >
    {
      modalStatus &&
      <React.Fragment>
        <h4>{modalContent[modalStatus].title1}</h4>
        <br />
        <h5>{modalContent[modalStatus].title2}</h5>
        <br />
      </React.Fragment>
    }
  </Modal>
);


RequestBudgetModal.propTypes = {
  modalStatus: PropTypes.string,
};

export default connect(mapStateToProps)(RequestBudgetModal);
