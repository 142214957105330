import { combineReducers } from 'redux-immutable';
import { Map } from 'immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { modalReducer } from '../src/components/Modal/reducer';
import { progressWidgetReducer } from '../src/components/ProgressWidget/reducer';
import { authReducer } from './auth/reducer';
import { wizardReducer } from '../Wizard/reducer';
import { spinnerReducer } from '../src/components/Spinner/reducer';
import error from './errorReducer';
import { registerReducer } from '../Register/reducer';

const combinedReducers = combineReducers({
  form: formReducer,
  modal: modalReducer,
  progressWidget: progressWidgetReducer,
  auth: authReducer,
  wizard: wizardReducer,
  spinner: spinnerReducer,
  error,
  register: registerReducer
});

/**
 * The root reducer.
 */
const rootReducer = (state = Map(), action) => {
  if (action.type === 'CLEAR_STATE') {
    return Map();
  }
  return combinedReducers(state, action);
};

export default rootReducer;
