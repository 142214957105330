import React from 'react';
import { Link } from 'react-router-dom';
import FooterLogo from '../../src/assets/images/logos/footer_logo.png';
import './footer.scss';

// FIXME: Grooming of empty links
const Footer = () => (
  <div className="footerWrapper">
    <div className="container">
      <div className="row">
        <div className="col-sm footerLogo">
          <Link to="/" className="navbar-brand topLogo">
            <img src={FooterLogo} alt="logo" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <ul className="footerList">
            <li>Unit 3, Canbury Business Park, Elm Crescent,</li>
            <li>Kingston Upon Thames - KT2 6HJ,</li>
            <li>London, United Kingdom</li>
            <li>T: +44 (0) 20 8549 2259</li>
            <li>E: info@needafixer.com</li>
          </ul>
        </div>
        <div className="col-sm">
          <ul className="footerList">
            <li><a href="http://www.needafixer.com">NEEDaFIXER</a></li>
            <li><Link to="/login">Log In</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/register">Register</Link></li>
          </ul>
        </div>
        <div className="col-sm">
          <ul className="footerList">
            <li>Facebook</li>
            <li>Twitter</li>
            <li>Instagram</li>
            <li>Vimeo</li>
            <li />
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
