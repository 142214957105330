import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import './input.scss';

const Input = ({
  customClass, meta, input, type, placeholder, textarea, hidden, label
}) => (
  hidden
    ? <input hidden={hidden} name={input.name} type={type || 'text'} placeholder={placeholder || `Insert ${input.name.toLowerCase()}`} {...input} />
    :
    <div className={['inputWrap', customClass].join(' ')}>
      <label htmlFor={input.name}>{ label ? label.toUpperCase() : input.name.toUpperCase()}</label>
      {
      textarea
        ? <textarea name={input.name} placeholder={placeholder || `Insert ${input.name.toLowerCase()}`} {...input} />
        : <input hidden={hidden} name={input.name} type={type || 'text'} placeholder={placeholder || `Insert ${input.name.toLowerCase()}`} {...input} />
    }

      <span className="errorMsg">{meta.touched && meta.error && meta.error}</span>
    </div>
);

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  meta: PropTypes.shape(),
  input: PropTypes.shape(),
  type: PropTypes.string,
  textarea: PropTypes.bool,
  hidden: PropTypes.bool
};

const InputField = props => (
  <Field
    component={Input}
    {...props}
  />
);

export default InputField;
