import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { formValueSelector, Field, change } from 'redux-form/immutable';
import './checkbox.scss';

const mapStateToProps = (state, ownProps) => ({
  checked: formValueSelector(ownProps.meta.form)(state, ownProps.input.name)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleChecked: checked => dispatch(change(ownProps.meta.form, ownProps.input.name, !checked))
});

const CheckboxRender = connect(mapStateToProps, mapDispatchToProps)(({
  input, label, customClass, toggleChecked, checked, meta: { error, submitFailed }
}) => (
  <div className="checkboxWrapper" onClick={() => toggleChecked(checked)} >
    <div className="row no-gutters">
      <input {...input} type="checkbox" />
      <span className={classnames('checkmark', customClass, { checked })} />
      <p>{label}</p>
    </div>
    <div className="row no-gutters checkBoxErrorMsg">
      <span className="errorMsg">{submitFailed && error}</span>
    </div>
  </div>
));

CheckboxRender.propTypes = {
  customClass: PropTypes.string,
  input: PropTypes.shape(),
  label: PropTypes.string,
  toggleChecked: PropTypes.func
};

const Checkbox = props => (
  <Field
    component={CheckboxRender}
    {...props}
  />
);

export default Checkbox;
