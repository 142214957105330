import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopLogo from '../../src/assets/images/logos/top_nav_logo.png';
import './navbar.scss';
import authTokenHandler from '../../core/auth/authTokenHandler';

const mapStateToProps = state => ({
  authenticated: state.getIn(['auth', 'authenticated'])
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    authTokenHandler.clearAuthToken();
    dispatch({ type: 'CLEAR_STATE' });
  }
});

const Navbar = ({ authenticated, logout }) => (
  <div className="top-nav">
    <div className="container">
      <nav className="navbar navbar-expand-lg">
        <Link to="/" className="navbar-brand topLogo">
          <img src={TopLogo} alt="logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#custom-navbar" aria-controls="custom-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className="collapse navbar-collapse" id="custom-navbar">
          <ul className="navbar-nav custom-navbar">
            <li className="nav-item">
              <Link to="/" className="nav-link custom-nav-item">HOME</Link>
            </li>
            <li className="nav-item">
              <Link to="contact" className="nav-link custom-nav-item" href="#">CONTACT US</Link>
            </li>
            { !authenticated ?
              <React.Fragment>
                <li className="nav-item">
                  <Link to="/register" className="nav-link custom-nav-item">REGISTER</Link>
                </li>
                <li className="nav-item">
                  <Link to="/login" className="nav-link custom-nav-item">LOG IN</Link>
                </li>
              </React.Fragment> :
              <li className="nav-item">
                <button onClick={logout} className="nav-link custom-nav-item">LOG OUT</button>
              </li>
            }
          </ul>
        </div>
      </nav>
    </div>
  </div>
);

Navbar.propTypes = {
  authenticated: PropTypes.bool,
  logout: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
