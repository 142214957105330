import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import getSymbolFromCurrency from 'currency-symbol-map';
import { faFilePdf, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './budgetQuote.scss';
import DownloadQuoteModal from './QuotePageModal';
import { ProductionServiceTitle } from '../Wizard/ProductionServices/ProductionServiceTitle';
import { BudgetCategory } from './BudgetCategory';
import { getStepByServiceCategory } from '../Wizard/configuration';
import { getTitleByServiceCategory } from '../Wizard/ProductionServices/serviceCategories';
import { contactUsApiRequest, downloadQuote } from './requests';
import { sortServiceCategories } from './utils';
import { progressWidgetActionTypes } from '../src/components/ProgressWidget/reducer';

const mapStateToProps = state => ({
  quote: state.getIn(['wizard', 'quote'])
});

const mapDispatchToProps = (dispatch, { history }) => ({
  editServiceCategory: (serviceCategory) => {
    dispatch({ type: progressWidgetActionTypes.SET_PROGRESS_STEP, payload: getStepByServiceCategory(serviceCategory) });
    history.push('/wizard');
  },
  downloadQuoteRequest: () => downloadQuote(dispatch),
  contactUsRequest: () => contactUsApiRequest(null, dispatch)
});


// FIXME: Pointer when hovering EDIT button on production services
class BudgetQuote extends React.Component {
  componentDidMount() {
    document.getElementById('quote-top').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  render() {
    const {
      quote, editServiceCategory, downloadQuoteRequest, contactUsRequest, history
    } = this.props;
    return (
      <div id="quote-top" className="container contentWrapper stepWrapper">
        <ProductionServiceTitle showDescription />
        { quote &&
        Object.entries(quote.productionServices).sort(sortServiceCategories).map((entry, index) =>
          (<BudgetCategory
            key={`${entry[0]}-${index}`}
            serviceCategory={getTitleByServiceCategory(entry[0])}
            serviceTypes={entry[1]}
            editServiceCategory={editServiceCategory}
            currency={getSymbolFromCurrency(quote.currency)}
          />))
        }
        <div className="col budgetTable">
          <div className="row budgetTableHead no-gutters">
            <div className="col"><h2>ADDITIONAL COSTS</h2></div>
          </div>
          <div className="col budgetTableContent">
            <div className="row budgetTableRow">
              <div className="d-flex align-items-center col">
                <h3>INSURANCE FEE (3%)</h3>
              </div>
              <div className="col-md-2 col-5 no-gutters">
                <span className="budgetPill budgetPricePill">{quote.insuranceFeeCost} {getSymbolFromCurrency(quote.currency)}</span>
              </div>
            </div>
            <div className="row budgetTableRow">
              <div className="d-flex align-items-center col">
                <h3>PRODUCTION FEE  (12%)</h3>
              </div>
              <div className="col-md-2 col-5 no-gutters">
                <span className="budgetPill budgetPricePill">{quote.productionFeeCost} {getSymbolFromCurrency(quote.currency)}</span>
              </div>
            </div>
            <div className="row budgetTableTotalRow no-gutters">
              <div className="d-flex justify-content-end col">
                <h2>SUBTOTAL</h2>
              </div>
              <div className="col-md-2 col-5 no-gutters">
                <span className="budgetPill budgetPricePill">{quote.insuranceFeeCost + quote.productionFeeCost} {getSymbolFromCurrency(quote.currency)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col budgetTotalWrapper">
          <div className="row no-gutters budgetTotalBox">
            <div className="col-md-4 col-auto budgetTotalContactBtn">
              <button onClick={() => contactUsRequest()} className="budgetAction">CONTACT a NEEDaFIXER COORDINATOR</button>
            </div>
            <div className="d-flex justify-content-end align-items-center col budgetTotalTitle col-auto">
              <h2>FINAL BUDGET PRICE</h2>
            </div>
            <div className="d-flex align-items-center col-md-2 col-auto no-gutters">
              <span className="budgetPill budgetPricePill">{quote.totalCost} {getSymbolFromCurrency(quote.currency)}</span>
            </div>
          </div>
          <div className="row no-gutters budgetActions">
            <div className="col" />
            <div className="col-md-3 col-12 budgetActionsWrapper">
              <button
                className="budgetAction budgetActionIcon"
                onClick={() => downloadQuoteRequest()}
              >
                <span>SAVE AS PDF</span><FontAwesomeIcon icon={faFilePdf} />
              </button>
            </div>
          </div>
          <div className="row col-auto actionsStripe">
            <div className="col-12">
              <button onClick={() => history.push('/wizard')} className="nextBtn">
                <FontAwesomeIcon icon={faAngleLeft} />BACK
              </button>
            </div>
          </div>
        </div>
        <DownloadQuoteModal />
      </div>
    );
  }
}

BudgetQuote.propTypes = {
  history: PropTypes.shape(),
  contactUsRequest: PropTypes.func,
  downloadQuoteRequest: PropTypes.func,
  quote: PropTypes.shape(),
  editServiceCategory: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BudgetQuote));
