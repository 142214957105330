import download from 'downloadjs';
import { getApiRequest, JSON_FORMAT } from '../core/utils/requestUtils';
import { modalState, setModalStateAction } from '../src/components/Modal/actions';
import { DOWNLOAD_QUOTE_MODAL } from './QuotePageModal';
import { modalStatuses } from '../src/components/Modal/constants';
import { setSpinnerActiveAction, setSpinnerInactiveAction } from '../src/components/Spinner/reducer';
import authTokenHandler from '../core/auth/authTokenHandler';

export const contactUsApiRequest = getApiRequest({
  authorized: true,
  target: '/api/project-manager/quota/contact',
  beforeRequestHandler: (dispatch) => {
    dispatch(setModalStateAction(modalState(DOWNLOAD_QUOTE_MODAL, modalStatuses.LOADING)));
  },
  successHandler: (dispatch) => {
    dispatch(setModalStateAction(modalState(DOWNLOAD_QUOTE_MODAL, modalStatuses.SUCCESS)));
  },
  errorHandler: (dispatch) => {
    dispatch(setModalStateAction(modalState(DOWNLOAD_QUOTE_MODAL, modalStatuses.FAILED)));
  }
});

export const downloadQuote = (dispatch) => {
  let filename;
  let contentType;
  dispatch(setSpinnerActiveAction());
  const Authorization = authTokenHandler.getAuthToken();
  const contentHeaders = {
    Accept: JSON_FORMAT,
    'Content-Type': 'application/pdf'
  };
  const headers = Authorization ? Object.assign({}, contentHeaders, { Authorization }) : contentHeaders;
  return fetch('/api/project-manager/quota/pdf', {
    method: 'GET',
    headers
  }).then((resp) => {
    if (resp.ok) {
      filename = resp.headers.get('FileName');
      contentType = resp.headers.get('Content-Type');
      return resp.blob();
    }
    throw new Error('Something went very very wrong!');
  }).then((resp) => {
    dispatch(setSpinnerInactiveAction());
    download(resp, filename, contentType);
  })
    .catch(() => {
      dispatch(setSpinnerInactiveAction());
      dispatch(setModalStateAction(modalState(DOWNLOAD_QUOTE_MODAL, modalStatuses.FAILED)));
    });
};
