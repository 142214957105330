import { SubmissionError } from 'redux-form/immutable';
import { postApiRequest } from '../core/utils/requestUtils';
import authTokenHandler from '../core/auth/authTokenHandler';
import { SET_AUTH } from '../core/auth/reducer';
import { WIZARD_PATH } from '../core/routing';
import { setSpinnerActiveAction, setSpinnerInactiveAction } from '../src/components/Spinner/reducer';

const AUTH_HEADER = 'Authorization';

const registerFormSubmissionHandler = history => postApiRequest({
  beforeRequestHandler: dispatch => dispatch(setSpinnerActiveAction()),
  target: 'api/login',
  httpResponseHandler: (dispatch, response) => {
    if (response.ok && response.headers.get(AUTH_HEADER)) {
      const headerValue = response.headers.get(AUTH_HEADER);
      // Store token in localStorage
      authTokenHandler.storeAuthToken(headerValue.split(' ')[1]);
      // Return the json promise
    }
  },
  successHandler: (dispatch) => {
    dispatch(setSpinnerInactiveAction());
    dispatch({ type: SET_AUTH });
    history.push(WIZARD_PATH);
  },
  errorHandler: dispatch => dispatch(setSpinnerInactiveAction()),
  exception: new SubmissionError({ _error: 'Login failed. Have you confirmed your e-mail?' })
});

export default registerFormSubmissionHandler;
