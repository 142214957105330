import { SubmissionError } from 'redux-form/immutable';
import { closeModalAction, modalState, setModalStateAction } from '../src/components/Modal/actions';
import { postApiRequest } from '../core/utils/requestUtils';
import { REGISTER_MODAL } from './constants';

const registerFormSubmissionHandler = postApiRequest({
  target: 'api/registration-manager',
  beforeRequestHandler: dispatch => dispatch(setModalStateAction(modalState(REGISTER_MODAL, 'loading'))),
  successHandler: dispatch => dispatch(setModalStateAction(modalState(REGISTER_MODAL, 'success'))),
  errorHandler: dispatch => dispatch(setModalStateAction(modalState(REGISTER_MODAL, 'failed'))),
  exception: new SubmissionError({ _error: 'Registration failed.' }),
  conflictHandler: (dispatch) => {
    dispatch(closeModalAction());
    throw new SubmissionError({ email: 'Email already exists.' });
  }
});

export default registerFormSubmissionHandler;
