import React from 'react';
import PropTypes from 'prop-types';
import StepperStripe from './StepperStripe';
import { withStepState } from './stepHanlders';
import { stepConfiguration } from '../../../Wizard/configuration';

const StepsContainer = ({ children, TitleComponent }) => (
  <div className="container contentWrapper stepWrapper">
    <TitleComponent />
    <StepperStripe stepConfiguration={stepConfiguration} top />
    {children}
    <StepperStripe stepConfiguration={stepConfiguration} />
  </div>
);

StepsContainer.propTypes = {
  children: PropTypes.node,
  TitleComponent: PropTypes.func
};

export default withStepState(StepsContainer);
