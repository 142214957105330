import { all } from 'redux-saga/effects';
import loginWithGoogleWatcher from '../Login/loginWithSocialSaga';

function* rootSaga() {
  yield all([
    loginWithGoogleWatcher()
  ]);
}

export default rootSaga;
