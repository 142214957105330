import React from 'react';
import 'load-awesome/css/ball-grid-pulse.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form/immutable';
import { parse } from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { TopLogo, InFormLogo } from '../src/components/images';
import { Input } from '../src/components';
import './login.scss';
import loginFormSubmissionHandler from './loginFormSubmissionHandler';
import { modalState, setModalStateAction } from '../src/components/Modal/actions';
import { getApiRequest } from '../core/utils/requestUtils';
import { EMAIL_CONFIRMATION_PATH } from '../core/routing';
import validations from '../src/components/ReduxForm/validations';
import Social from '../src/components/Social';
import EmailConfirmationModal, { CONFIRM_EMAIL_MODAL } from './EmailConfirmationModal';
import { LOGIN_GOOGLE_REDIRECT } from './googleConstants';
import LoginWithGoogleModal from './LoginWithGoogleModal';
import { LOGIN_FACEBOOK_REDIRECT } from './facebookConstants';

const mapDispatchToProps = dispatch => ({
  confirmPasswordHandler: ({ hash }) => {
    getApiRequest({
      target: `/api/registration-manager?hash=${hash}`,
      beforeRequestHandler: () => dispatch(setModalStateAction(modalState(CONFIRM_EMAIL_MODAL, 'loading'))),
      successHandler: () => dispatch(setModalStateAction(modalState(CONFIRM_EMAIL_MODAL, 'success'))),
      errorHandler: () => dispatch(setModalStateAction(modalState(CONFIRM_EMAIL_MODAL, 'failed'))),
    })(null, dispatch);
  }
});

const mapStateToProps = state => ({
  formError: state.getIn(['form', 'login', 'error'])
});

class Login extends React.Component {
  componentWillMount() {
    if (this.props.location.pathname === EMAIL_CONFIRMATION_PATH) {
      this.props.confirmPasswordHandler(parse(this.props.location.search));
    }
  }

  render() {
    return (
      <div className="contentWrapper darkPageBg loginFormWrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="formWrapper col-md-5 col-sm-8 col">
              <div className="row formHeader">
                <div className="col-4">
                  <p className="signUpTitle">LOGIN</p>
                </div>
                <div className="col-8 text-right topLogoRegister">
                  <img src={TopLogo} alt="logo" />
                </div>
              </div>
              <div className="row justify-content-center registerWrapper">
                <form className="flex col">

                  <div className="row justify-content-center inFormLogo">
                    <img src={InFormLogo} alt="logo" />
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-10 inputWrapper">
                      <FontAwesomeIcon className="inputIcon" icon={faEnvelope} />
                      <Input
                        name="email"
                        customClass="inputGeneral inpuWithIcon"
                        type="email"
                        placeholder="Enter your e-mail"
                        label="E-MAIL"
                        validate={[validations.required, validations.email]}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-10 inputWrapper">
                      <FontAwesomeIcon className="inputIcon" icon={faLock} />
                      <Input
                        name="password"
                        customClass="inputGeneral inpuWithIcon"
                        type="password"
                        placeholder="Enter your password"
                        label="PASSWORD"
                        validate={[validations.required]}
                      />
                    </div>
                  </div>
                  <div className="justify-content-center text-center captchWrapper" />
                  <div className="row justify-content-center text-center">
                    <div className="col-10">
                      {/* <div className="btn action-btn"> */}
                      <button
                        type="submit"
                        onClick={this.props.handleSubmit(loginFormSubmissionHandler(this.props.history))}
                        className="action-btn"
                      >
                        LOGIN
                      </button>
                      {
                        this.props.formError &&
                        <span className="errorMsg">{this.props.formError}</span>
                      }
                      {/* </div> */}
                    </div>
                  </div>
                </form>
              </div> {/* form div end */}
              <div className="row justify-content-center">
                <div className="row">
                  <div className="col-12 forgotPass">
                    <Link to="/forgot-password" onClick={this.spinClick}>Forgot my password</Link>
                  </div>
                </div>
              </div>
              <Social googleRedirect={LOGIN_GOOGLE_REDIRECT} facebookRedirect={LOGIN_FACEBOOK_REDIRECT} />
            </div>
          </div>
        </div>
        <EmailConfirmationModal />
        <LoginWithGoogleModal />
      </div>
    );
  }
}

Login.propTypes = {
  formError: PropTypes.string,
  handleSubmit: PropTypes.func,
  confirmPasswordHandler: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
};

export default reduxForm({
  form: 'login'
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
