import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModalNameSelector, modalStatusSelector } from './selector';
import './modal.scss';
import { closeModalAction } from './actions';
import { ErrorMessage } from '../';

import ModalFail from '../../assets/images/modal_failed.png';
import ModalSuccess from '../../assets/images/modal_success.png';
import ModalLoading from '../../assets/images/modal_loading.png';
import ModalNotification from '../../assets/images/modal_notification.png';
import { modalStatuses } from './constants';

const modalIcons = {
  [modalStatuses.FAILED]: ModalFail,
  [modalStatuses.LOADING]: ModalLoading,
  [modalStatuses.NOTIFICATION]: ModalNotification,
  [modalStatuses.SUCCESS]: ModalSuccess
};
const mapStateToProps = state => ({
  modalStatus: modalStatusSelector(state),
  openModalName: openModalNameSelector(state)
});

const mapDispatchToProps = (dispatch, { onCloseModal }) => ({
  closeModal: () => {
    dispatch(closeModalAction());
    if (typeof onCloseModal === 'function') {
      onCloseModal(dispatch);
    }
  },
  cancel: () => dispatch(closeModalAction())
});

const setDisabled = modalStatus => `${modalStatus === modalStatuses.LOADING ? 'disabled' : ''}`;

const renderButtons = (twoButtons, modalStatus, closeModal, cancel) => (
  twoButtons ?
    <div className="row modalButtonsWrapper">
      <button disabled={setDisabled(modalStatus)} className="action-btn" onClick={closeModal}>OK</button>
      <button disabled={setDisabled(modalStatus)} className="action-btn" onClick={cancel}>CANCEL</button>
    </div> :
    <div className="row modalButtonsWrapper justify-content-center">
      <button disabled={setDisabled(modalStatus)} className="col-5 action-btn" onClick={closeModal}>CLOSE</button>
    </div>
);

const Modal = ({
  openModalName, modalName, customClass, children, closeModal, modalStatus, error, twoButtons, cancel
}) => (
  openModalName === modalName &&
  <div className={['modalFixerWrap active', customClass].join(' ')}>
    <div className="modalFixerContent">
      <div className="modalIconWrapper">
        {modalStatus ? (<img src={modalIcons[modalStatus]} alt="modal" />) : null}
      </div>
      {children}
      {
        modalStatus !== modalStatuses.LOADING &&
        renderButtons(twoButtons, modalStatus, closeModal, cancel)
      }
      {
        error && <ErrorMessage error={error} />
      }
    </div>
    <div className="modalFixerBackdrop" />
  </div>
);

Modal.propTypes = {
  error: PropTypes.string,
  openModalName: PropTypes.string,
  modalStatus: PropTypes.oneOf([
    modalStatuses.FAILED, modalStatuses.FAILED, modalStatuses.NEUTRAL, modalStatuses.NOTIFICATION, modalStatuses.LOADING
  ]),
  customClass: PropTypes.string,
  children: PropTypes.node,
  twoButtons: PropTypes.bool,
  cancel: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
