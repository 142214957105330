import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from '../src/components/Modal/Modal';
import { modalStatusSelector } from '../src/components/Modal/selector';
import { REGISTER_MODAL } from './constants';
import { modalStatuses } from '../src/components/Modal/constants';

const modalContent = {
  [modalStatuses.LOADING]: {
    title1: 'Registering you new account. ',
    title2: 'Please wait...'
  },
  [modalStatuses.SUCCESS]: {
    title1: 'You account has been registered successfully.',
    title2: 'Please check your email for the confirmation e-mail'
  },
  [modalStatuses.FAILED]: {
    title1: 'There was a problem processing your request. Please try again',
    title2: 'If the problem persists, please contact us.'
  }
};

const mapStateToProps = state => ({
  modalStatus: modalStatusSelector(state)
});

const RegisterModal = ({ modalStatus, history }) => (
  <Modal
    onCloseModal={modalStatus === modalStatuses.FAILED ? undefined : () => history.push('/login')}
    modalName={REGISTER_MODAL}
  >
    {
      modalStatus &&
      <React.Fragment>
        <h4>{modalContent[modalStatus].title1}</h4>
        <br />
        <h5>{modalContent[modalStatus].title2}</h5>
        <br />
      </React.Fragment>
    }
  </Modal>
);


RegisterModal.propTypes = {
  modalStatus: PropTypes.string,
  history: PropTypes.shape()
};

export default connect(mapStateToProps)(withRouter(RegisterModal));
