export const modalStatuses = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed',
  NEUTRAL: 'neutral',
  NOTIFICATION: 'notification'
};

export const storeLiterals = {
  OPEN_MODAL: 'openModalName',
  MODAL_STATUS: 'modalStatus',
  MODAL: 'modal'
};

