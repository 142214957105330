import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Counter } from '../../src/components';

class FormField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }
  render() {
    const {
      numberOfCols,
      maxQuantity,
      productionService,
      titleForAmount
    } = this.props;
    return (
      <React.Fragment>
        <div className="d-flex col col-md-5 col-12 stepRowHead">
          <h4>{productionService}</h4>
          <div
            onClick={() => this.setState({ active: !this.state.active })}
            className={this.state.active ? 'd-flex d-md-none stepRowHeadToggle' : 'd-flex d-md-none stepRowHeadToggle inactive'}
          >
            <FontAwesomeIcon size="2x" color="#f73e18" icon={faAngleUp} />
          </div>
        </div>
        <div className={!this.state.active ? 'd-none d-md-flex col-md-6 stepRowContent' : 'd-flex col-md-6 stepRowContent'}>
          <div className={`col counterCol col-md-${numberOfCols === 3 ? 4 : 6} col-12`}>
            <div className="row no-gutters">
              <div className="col col-8 d-md-none d-flex align-items-center">
                <p>{titleForAmount}</p>
              </div>
              <div className="col col-4 col-md-12 d-flex flex-column align-items-center">
                <Counter max={100} name={`${productionService}.numberOfPersons`} />
              </div>
            </div>
          </div>
          {
            numberOfCols === 3 &&
            <div className={`col counterCol col-md-${numberOfCols === 3 ? 4 : 6} col-12`}>
              <div className="row no-gutters">
                <div className="col col-8 d-md-none d-flex align-items-center">
                  <p>PRE-PRODUCTION DAYS</p>
                </div>
                <div className="col col-4 col-md-12 d-flex flex-column align-items-center">
                  <Counter name="preProductionDays" fieldLevelValidator={value => (value > maxQuantity ? 'Cannot be greater than project duration in days' : undefined)} />
                </div>
              </div>
            </div>
          }
          <div className={`col counterCol col-md-${numberOfCols === 3 ? 4 : 6} col-12`}>
            <div className="row no-gutters">
              <div className="col col-8 d-md-none d-flex align-items-center">
                <p>PRODUCTION DAYS</p>
              </div>
              <div className="col col-4 col-md-12 flex-column d-flex align-items-center">
                <Counter max={maxQuantity} name={`${productionService}.productionDays`} fieldLevelValidator={value => (value > maxQuantity ? 'Cannot be greater than project duration in days' : undefined)} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
FormField.propTypes = {
  productionService: PropTypes.string,
  titleForAmount: PropTypes.string,
  numberOfCols: PropTypes.number,
  maxQuantity: PropTypes.number
};

const ProductionServiceField = ({
  serviceType, maxQuantity, titleForAmount, numberOfCols
}) => (
  <FormField
    productionService={serviceType}
    titleForAmount={titleForAmount}
    maxQuantity={maxQuantity}
    numberOfCols={numberOfCols}
  />
);


ProductionServiceField.propTypes = {
  serviceType: PropTypes.string,
  titleForAmount: PropTypes.string,
  numberOfCols: PropTypes.number,
  maxQuantity: PropTypes.number
};

export default ProductionServiceField;

