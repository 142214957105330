import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form/immutable';
import './counter.scss';

const mapDispatchToProps = (dispatch, { input, meta, max }) => ({
  increase: () => {
    if (input.value + 1 > max) return;
    dispatch(change(meta.form, input.name, input.value + 1));
  },
  reduce: () => {
    if (input.value - 1 < 0) return;
    dispatch(change(meta.form, input.name, input.value - 1));
  }
});

const RenderCounter = connect(null, mapDispatchToProps)(({
  input, increase, reduce, meta: { error }
}) => (
  <React.Fragment>
    <div className="counterWrapper">
      <div className="row">
        <div className="col actions" onClick={reduce}>
          <div className="actionBtn">-</div>
        </div>

        <div className="col counterValue">
          <input
            disabled
            type="text"
            className={classnames('counterInput', { error })}
            {...input}
          />
        </div>
        <div className="col actions" onClick={increase}>
          <div className="actionBtn">+</div>
        </div>
      </div>
    </div>
    <span className="errorSpanCounter">{error}</span>
  </React.Fragment>
));

RenderCounter.propTypes = {
  input: PropTypes.shape(),
  increase: PropTypes.func,
  reduce: PropTypes.func
};

const parse = value => (value ? parseInt(value, 10) : 0);

const Counter = ({
  name, max, fieldLevelValidator
}) => (
  <Field
    validate={fieldLevelValidator}
    max={max}
    format={parse}
    parse={parse}
    name={name}
    component={RenderCounter}
  />
);

Counter.propTypes = {
  fieldLevelValidator: PropTypes.func,
  name: PropTypes.string,
  max: PropTypes.number,
};

export default Counter;
