import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from '../src/components/Modal/Modal';
import { modalStatuses } from '../src/components/Modal/constants';
import { modalStatusSelector } from '../src/components/Modal/selector';

const modalContent = {
  [modalStatuses.LOADING]: {
    title1: 'Processing your request.',
    title2: 'Please wait...'
  },
  [modalStatuses.SUCCESS]: {
    title1: 'Your request has been completed successfully',
    title2: 'Please check your email for instructions on how to reset your password'
  },
  [modalStatuses.FAILED]: {
    title1: 'There was a problem processing your request. Please try again',
    title2: 'If the problem persists, please contact us.'
  }
};

export const FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL';


const mapStateToProps = state => ({
  modalStatus: modalStatusSelector(state),
});

const ForgotPasswordModal = ({ modalStatus, history }) => (
  <Modal
    modalName={FORGOT_PASSWORD_MODAL}
    onCloseModal={() => history.push('/login')}
  >
    {
      modalStatus &&
      <React.Fragment>
        <h4>{modalContent[modalStatus].title1}</h4>
        <br />
        <h5>{modalContent[modalStatus].title2}</h5>
        <br />
      </React.Fragment>
    }
  </Modal>
);

export default connect(mapStateToProps)(withRouter(ForgotPasswordModal));

ForgotPasswordModal.propTypes = {
  history: PropTypes.shape(),
  modalStatus: PropTypes.oneOf([
    modalStatuses.FAILED, modalStatuses.FAILED, modalStatuses.NEUTRAL, modalStatuses.NOTIFICATION, modalStatuses.LOADING
  ])
};
