import { connect } from 'react-redux';
import { stepSelector } from './selector';
import { setProgressStepAction } from './actions';

const mapStateToProps = state => ({
  currentWizardStep: stepSelector(state) || 0
});

const mapDispatchToProps = dispatch => ({
  setStepHandler: selectedStep => dispatch(setProgressStepAction(selectedStep))
});

export const withStepHandler = Component => connect(mapStateToProps, mapDispatchToProps)(Component);
export const withStepState = Component => connect(mapStateToProps)(Component);
