import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './spinner.scss';

const mapStateToProps = state => ({
  active: state.getIn(['spinner', 'active'])
});

const Spinner = ({ active }) => (
  <div className={classnames('spinner-loader', { active })}>
    <div className="la-ball-grid-pulse la-white la-3x">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

Spinner.propTypes = {
  active: PropTypes.bool
};

export default connect(mapStateToProps)(Spinner);
