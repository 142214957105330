import { Map } from 'immutable';
import { CLEAR_ERROR, SET_ERROR } from '../ErrorPage/actions';

const error = (state = Map(), action) => {
  switch (action.type) {
    case SET_ERROR:
      return Map(action.payload.error);
    case CLEAR_ERROR:
      return Map();
    default:
      return state;
  }
};

export default error;
