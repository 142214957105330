import React, { Fragment } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { REGISTER_RECAPTCHA_KEY } from './constants';

const renderRegisterRecaptcha = field => (
  <Fragment>
    <div className="justify-content-center text-center captchWrapper">
      <div className="col-12">
        <ReCAPTCHA
          sitekey={REGISTER_RECAPTCHA_KEY}
          onChange={field.input.onChange}
        />
        <span className="errorMsg">{field.meta.touched && field.meta.error}</span>
      </div>
    </div>
  </Fragment>
);

export default renderRegisterRecaptcha;
